/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import { useNavigate, useParams, Link } from 'react-router-dom';
 
const EditUser = () => {
    const { id } = useParams();
    const [name, setName] = useState('');
    const [company, setCompany] = useState([]);
    const [role, setRole] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [msg, setMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);    
    const [access, setAccess] = useState([]);
    const [formData, setFormData] = useState({
        user_role: '0',
        cname: '',
        fname: '',
        lname: '',
        email: '',
        officephone: '',
        mobilephone: '',
        pbx_switch: false,
        tns_api_access: false,
        enrms_access_switch: false,
        pbx_url:null,
        pbx_username:null,
        pbx_password:null
    });
    const [accessRight, setAccessRight] = useState({
        createuser: false,
        edituser: false,
        deleteuser: false,
        viewuser: false,
        ordernewnumber: false,
        viewtransactions: false,
        modifynumber: false,
        cancelnumber: false,
        viewinventory: false
    });
    const [setting, setSetting] = useState({
        disconnectSwitch: false,
        disconnectday: 0,
    });
    const [trunkData, setTrunksData] = useState([]);
    const [whiteLabelData, setWhiteLabelData] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [next, setNext] = useState(false);

    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
      }, []);
    useEffect(() => {
        if (token && id) {
          checAccess(id);
        }
    }, [token, id]);
    
      useEffect(() => {
        if (token && next) {
          getUserCompany();
          getUserDetail();
          getUserAccess();
        }
      }, [token && next]);
      useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
        
    }, [access, token]);
      
 
    const refreshToken = async () => {
        try {
            setPopupOpen(true);
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setPopupOpen(false);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
            
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axios.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
      };

    const handleSwitchChange = (event) => {
        const { name, checked } = event.target;
        setSetting((prevSetting) => ({
            ...prevSetting,
            [name]: checked,
        }));
    };

    const handlePBXSwitchChange = (event) => {
        const { name, checked } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    }

    const handleTnsSwitchChange = (event) => {
        const { name, checked } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    }


    const handleDayChange = (event) => {
        const { name, value } = event.target;
        setSetting((prevSetting) => ({
            ...prevSetting,
            [name]: parseInt(value),
        }));
    };

    const handleCheckboxChange = (event, name, type) => {
        const isChecked = event.target.checked;
        if(type === 0){
            if (isChecked) {
                setTrunksData([...trunkData, name]);
            } else {
                setTrunksData(trunkData.filter(n => n !== name));
            }
        }else{
            if (isChecked) {
                setWhiteLabelData([...whiteLabelData, name]);
            } else {
                setWhiteLabelData(whiteLabelData.filter(n => n !== name));
            }
        }
        
    };

    const checAccess = async(id) => {
        try {
            setPopupOpen(true);
            const response =  await axios.get(`${baseUrl}/check-access/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            // setPopupOpen(false);
            // console.log("sddsdsd");
            // console.log(response.data);
            // console.log(response.data.isAccess);
            if(!response.data.isAccess){
                setNext(false);
                navigate('/dashboard');
              
            }else{
                setNext(true);
            }
            
            // setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
            // console.log("ddsd")
            // console.log(error);
            setNext(false);
            navigate('/dashboard');
        }
        // console.log(id);
    }

    const checkAccess = async (access) => {
        if((role === 2 && !hasPermission('edituser')) || role === 5){
            navigate('/dashboard');
        }
    }
   
    const getUserCompany = async () => {
        
        const response = await axios.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data);
        setCompany(response.data);
    }

    const getUserDetail = async () => {
        try {
            setPopupOpen(true);
            const response = await axios.get(`${baseUrl}/get-user-detail/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setPopupOpen(false);
            // console.log(response.data);
            const userData = response.data.user[0];
            const companyDetailsData = response.data.company_details;
            const enrmsDetailData = response.data.enrms;
            console.log(enrmsDetailData, "enrmsDetailData");
            console.log("companyDetailsData",companyDetailsData);
            setFormData({
                user_role: userData.role, // Convert to string if needed
                cname: userData.cid,
                fname: userData.fname,
                lname: userData.lname,
                email: userData.email,
                officephone: userData.office_phone,
                mobilephone: userData.mobile_phone,
                pbx_switch: userData.pbx_switch === 1,
                tns_api_access: userData.tns_api_access === 1,
                enrms_access_switch: userData.enrms_access_switch === 1,
                pbx_url: userData.pbx_url,
                pbx_username: userData.pbx_username,
                pbx_password: userData.pbx_password,
                enable_tns_api:companyDetailsData[0]?.enable_tns_api,
                cia_enable_switch : enrmsDetailData[0]?.cia_enable_switch,
                cia_account_type : enrmsDetailData[0]?.cia_account_type
            });
            const userAccessData = response.data.user_access;
            

            const updatedAccessRight = { ...accessRight }; // Create a copy of the state
            
            userAccessData.forEach((access) => {
              const { access: accessName, value } = access;
              updatedAccessRight[accessName] = value === 1; // Convert value to boolean
            });
      
            setAccessRight(updatedAccessRight);

            //   const userSettingData = response.data.user_setting[0];
            //   if (userSettingData) {
            //     setSetting({
            //         disconnectSwitch: userSettingData.disconnect_switch === 1,
            //         disconnectday: userSettingData.disconnect_day,
            //     });
            // } 
            // console.log(setting.disconnectSwitch);

            //   const userTrunk = response.data.user_trunk;;
            //   setTrunksData(userTrunk);
            //   const userLabel = response.data.user_white_label;;
            //   setWhiteLabelData(userLabel);
            // console.log(userData);
        }catch{
            navigate('/dashboard');
        }
    };  
    const [validationErrors, setValidationErrors] = useState({
        user_role: false,
        email: false,
        cname: false,
        fname: false,
        lname: false,
        officephone: false,
        mobilephone: false

    });
    const formRef = useRef(null);
    const validateEmail = (email) => {
        // Implement your email validation logic here
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const newValidationErrors = {
            user_role: formData.user_role === '0',
            email: !validateEmail(formData.email),
            cname: formData.cname === '',
            fname: formData.fname === '',
            lname: formData.lname === '',
            officephone: !/^\d{1,10}$/.test(formData.officephone),
            mobilephone: !/^\d{1,10}$/.test(formData.mobilephone)


          };
          setValidationErrors(newValidationErrors);
      
          // Check if there are any validation errors
          const hasErrors = Object.values(newValidationErrors).some((error) => error);
      
          if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            // console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          } else {
            // If no validation errors, proceed with form submission
            // console.log('Form submitted with data:', formData);
            // console.log(token);
            const rightsArray = Object.entries(accessRight).map(([key, value]) => ({
                name: key,
                value: value ? 1 : 0,
              }));
             
              const combinedData = {
                formData: formData,
                rightsArray: rightsArray,
                setting :setting,
                trunks: trunkData,
                whitelabel:whiteLabelData
              };
              console.log(combinedData);
            try {
                setButtonDisabled(true);
                setPopupOpen(true);
                await axios.put(`${baseUrl}/user/edit/${id}`,combinedData,{headers: {
                    Authorization: `Bearer ${token}`
                }});
                setIsSuccess(true);
                navigate("/user");
            } catch (error) {
                setButtonDisabled(false);
                setPopupOpen(false);
                if (error.response) {
                    setIsSuccess(false);
                    setMsg(error.response.data.msg);
                }
            }
          }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'user_role' && value <= 2) {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                cname: '1' // Set cname to '1' if user_role is less than or equal to 2
            }));
            // setTrunksData([]);
            // setWhiteLabelData([]);
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

      const accessRightLabels = {
        createuser: 'Create Users',
        edituser: 'Edit Users',
        deleteuser: 'Delete Users',
        viewuser: 'View Users',
        ordernewnumber: 'Order New Number',
        viewtransactions: 'View Orders',
        modifynumber: 'Modify Number',
        cancelnumber: 'Cancel Number',
        viewinventory: 'View Inventory'        
      };
    
 
    return (
        <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page admin-new-addstaff-page">
                    <div className="fullrow">
                        <div className="row">
                            <div className="col-6">
                                <div className="left-title">
                                    <h1>Edit User</h1>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="addnew-button">
                                    <Link to="/user"><span>&larr;</span> Back</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                            <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p>
                                <div className="edit-staff-data-form add-new-staff-form">
                                    <form className="form-box" onSubmit={handleSubmit} ref={formRef}>
                                        <div className="common-white-shadow-background">
                                            <div className="innerboxwidth">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h3>User Info</h3>
                                                    </div>
                                                    <div className="col-6">
                                                    <label>User Types</label>
                                                        <select name="user_role"  disabled id="user_role"  value={formData.user_role} onChange={handleInputChange} className={validationErrors.user_role ? 'invalid' : ''}>
                                                            <option value="0">User Types</option>                
                                                            {role === 0 &&<option value="1">Super Admin</option>}
                                                            {role <= 2 && <option value="2">BBD Support User</option>}
                                                            {role <= 2 && <option value="3">Reseller admin user</option>}
                                                            {role <= 3 && <option value="4">Customer admin user</option>}
                                                            {role <= 5 && <option value="5">Customer user</option>}
                                                        </select>
                                                        {validationErrors.user_role && <div className="error error-message">Please select a user role.</div>}
                                                    </div>
                                                    <div className="col-md-12">
                                                    <label>Company</label>
                                                        <select name="cname" disabled value={formData.cname} onChange={handleInputChange} className={validationErrors.cname ? 'invalid' : ''} >
                                                            <option value="">Select a company</option>
                                                            {company.map((comp, index) => (
                                                              <option key={index} value={comp.id}>
                                                                {comp.cname}
                                                              </option>
                                                            ))}
                                                        </select>
                                                        {validationErrors.cname && <div className="error error-message">Please Enter Company Name.</div>}
                                                    </div>
                                                    <div className="col-md-6">
                                                    <label>First Name</label>
                                                        <input type="text" name="fname" defaultValue={formData.fname} placeholder="First Name*" onChange={handleInputChange} className={validationErrors.fname ? 'invalid' : ''} />
                                                        {validationErrors.fname && <div className="error error-message">Please Enter First Name.</div>}
                                                    </div>
                                                    <div className="col-md-6">
                                                    <label>Last Name</label>
                                                        <input type="text" name="lname" defaultValue={formData.lname} placeholder="Last Name*"  onChange={handleInputChange} className={validationErrors.lname ? 'invalid' : ''} />
                                                        {validationErrors.lname && <div className="error error-message">Please Enter last name.</div>}
                                                    </div>
                                                    <div className="col-sm-12">
                                                    <label>Email</label>
                                                        <input type="email" name="email" disabled={(role > 2 )} defaultValue={formData.email} placeholder="Email"  onChange={handleInputChange} className={validationErrors.email ? 'invalid' : ''}/>
                                                        
                                                        {validationErrors.email && <div className="error error-message">Please enter a valid email address.</div>}
                                                    </div>

                                                    <div className="col-sm-6">
                                                    <label>Office Phone Number</label>
                                                        <input type="number" name="officephone" defaultValue={formData.officephone}
                                                            pattern="[0-9]*"  placeholder="Office Phone Number*" onChange={handleInputChange} className={validationErrors.officephone ? 'invalid' : ''} />
                                                            {validationErrors.officephone && <div className="error error-message">Phone number should not be longer than 10-digits.</div>}
                                                    </div>
                                                    <div className="col-sm-6">
                                                    <label>Mobile Phone Number</label>
                                                        <input type="number" name="mobilephone" defaultValue={formData.mobilephone}
                                                            pattern="[0-9]*"  placeholder="Mobile Phone Number*" onChange={handleInputChange} className={validationErrors.mobilephone ? 'invalid' : ''} />
                                                            {validationErrors.mobilephone && <div className="error error-message">Phone number should not be longer than 10-digits.</div>}
                                                    </div>
                                                    
                                                    {(formData.user_role == '2' || formData.user_role == '5') && <div className="col-sm-12">
                                                        <p>Access Rights</p>
                                                        <div className="checkboximage">
                                                        {/* {Object.entries(accessRight).map(([key, value]) => (
                                                            <label key={key} htmlFor={key}>
                                                            <input type="checkbox" name={key} id={key} checked={value} onChange={(e) =>
                                                                setAccessRight((prevRights) => ({
                                                                  ...prevRights,
                                                                  [key]: e.target.checked,
                                                                }))}
                                                            />
                                                            {accessRightLabels[key]}
                                                            </label>
                                                        ))} */}
                                                        {Object.entries(accessRight)
                                                        .filter(([key]) => {
                                                            // formData.user_role !== '5' || !['createuser', 'edituser', 'deleteuser', 'viewuser'].includes(key)
                                                            if (formData.user_role != '5' ) {
                                                                // If user_role is 5, no filtering is applied
                                                                // return true;
                                                                return !['modifynumber'].includes(key);
                                                              } else {
                                                                // Otherwise, filter out specific keys (e.g., 'createuser', 'edituser', etc.)
                                                                return !['createuser', 'edituser', 'deleteuser', 'viewuser', 'modifynumber', 'viewinventory'].includes(key);
                                                              }
                                                            
                                                        })
                                                        .map(([key, value]) => (
                                                            <label key={key} htmlFor={key}>
                                                            <input type="checkbox" name={key} id={key} checked={value} onChange={(e) =>
                                                                setAccessRight((prevRights) => ({
                                                                  ...prevRights,
                                                                  [key]: e.target.checked,
                                                                }))}
                                                            />
                                                            {accessRightLabels[key]}
                                                            </label>
                                                        ))}
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    
                                     {(formData.user_role == '3' || formData.user_role == '4' || formData.user_role == '5' ) && <div className="common-white-shadow-background setting-section">
                                        <div className="innerboxwidth">
                                            <div className="row">
                                                <h3>Settings</h3>
                                                <div className="settingbox">
                                                <label>Cloud PBX Access</label>
                                                    <div className="switchbtn">
                                                        <input type="checkbox" id="pbxswitch" className="checkbox"  checked={formData.pbx_switch === true} name="pbx_switch"  onChange={handlePBXSwitchChange} /> <label
                                                            htmlFor="pbxswitch" className="toggle">
                                                            <p>
                                                               
                                                            </p>
                                                        </label>
                                                    </div>
                                                    <div className="daybox">
                                                        {/* {setting.disconnectSwitch && (<label htmlFor="">Days <input type="text" name="disconnectday"
                                                            placeholder="120" value={setting.disconnectday} onChange={handleDayChange} /></label>
                                                        )} */}
                                                    </div>
                                                </div>
                                                {formData.pbx_switch && <div className="row">
                                                        <div className="col-sm-12">
                                                            <input type="text" name="pbx_url" defaultValue={formData.pbx_url}
                                                              placeholder="PBX cloud url" onChange={handleInputChange}  />
                                                            
                                                        </div>
                                                        {/* <div className="col-sm-6">
                                                            <input type="text" name="pbx_username" defaultValue={formData.pbx_username}
                                                              placeholder="PBX cloud username" onChange={handleInputChange}  />
                                                            
                                                        </div>
                                                        <div className="col-sm-6">
                                                        <input type="password" name="pbx_password" defaultValue={formData.pbx_password}
                                                             placeholder="PBX cloud password" onChange={handleInputChange}  />
                                                            
                                                        </div> */}
                                                    </div>}
                                                
                                                {/* TNS Settings */}
                                                {formData.enable_tns_api==1 && <div className="settingbox">
                                                <label>Enable TNS Access</label>
                                                    <div className="switchbtn">
                                                        <input type="checkbox" id="tnsApiAccess" className="checkbox"  checked={formData.tns_api_access === true} name="tns_api_access"  onChange={handleTnsSwitchChange} /> <label
                                                            htmlFor="tnsApiAccess" className="toggle">
                                                            <p>
                                                               
                                                            </p>
                                                        </label>
                                                    </div>
                                                    <div className="daybox">
                                                        {/* {setting.disconnectSwitch && (<label htmlFor="">Days <input type="text" name="disconnectday"
                                                            placeholder="120" value={setting.disconnectday} onChange={handleDayChange} /></label>
                                                        )} */}
                                                    </div>
                                                </div>}
                                                {formData.cia_enable_switch == 1 && formData.cia_account_type == 1 && <div className="settingbox">
                                                <label>Enable ENRMS Access</label>
                                                    <div className="switchbtn">
                                                        <input type="checkbox" id="enrmsAccessSwitch" className="checkbox"  checked={formData.enrms_access_switch === true} name="enrms_access_switch"  onChange={handleTnsSwitchChange} /> <label
                                                            htmlFor="enrmsAccessSwitch" className="toggle">
                                                            <p>
                                                               
                                                            </p>
                                                        </label>
                                                    </div>
                                                    
                                                </div>}
                                            </div>
                                        </div>
                                    </div>}
                                    {/*{(formData.user_role == '3' || formData.user_role == '4' ) && <div className="common-white-shadow-background setting-section">
                                        <div className="row">
                                            <div className="col-7">
                                                <h3>Trunks</h3>
                                            </div>
                                            <div className="col-5">
                                                <input className="search-leave satff-listing-page-search" type="text"
                                                    name="search" placeholder="Search" style={{width:'100%'}} />
                                            </div>
                                            <div className="tablebox">
                                                <div className="fullwidth-table track-table-body staff-listing-admin-table-body">
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>TID</th>
                                                            <th colSpan="2">Name</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" checked={trunkData.some((item) => item.name === 'ORG BROADBAND-DYNAMICS_LA1')} onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_LA1', value:45265}, 0)} /></td>
                                                            <td>45265</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_LA1</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" checked={trunkData.some((item) => item.name === 'ORG BROADBAND-DYNAMICS_TOR1')} onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_TOR1',  value:45281}, 0)} /></td>
                                                            <td>45281</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_TOR1</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" checked={trunkData.some((item) => item.name === 'ORG BROADBAND-DYNAMICS_MN1')} onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_MN1',  value:45282}, 0)} /></td>
                                                            <td>45282</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_MN1</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" checked={trunkData.some((item) => item.name === 'ORG BROADBAND-DYNAMICS_SLC1')} onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_SLC1',  value:45283}, 0)} /></td>
                                                            <td>45283</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_SLC1</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" checked={trunkData.some((item) => item.name === 'ORG BROADBAND-DYNAMICS_LA2')} onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_LA2', value:45284}, 0)} /></td>
                                                            <td>45284</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_LA2</td>
                                                           
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" checked={trunkData.some((item) => item.name === 'ORG BROADBAND-DYNAMICS_SLC2')} onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_SLC2', value:45554}, 0)} /></td>
                                                            <td>45554</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_SLC2</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {(formData.user_role == '3' || formData.user_role == '4') && <div className="common-white-shadow-background setting-section">
                                        <div className="row">
                                            <div className="col-7">
                                                <h3>White Label SBC Trunks <img src={`${process.env.PUBLIC_URL}/images/bx-refresh.png`} style={{background: '#f5f5f5', borderRadius: '2px'}} /></h3>
                                            </div>
                                            <div className="col-5">
                                                <input className="search-leave satff-listing-page-search" type="text"
                                                    name="search" placeholder="Search" style={{width:'100%'}} />
                                            </div>
                                            <div className="tablebox">
                                                <div className="fullwidth-table track-table-body staff-listing-admin-table-body">
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>TID</th>
                                                            <th colSpan="2">Name</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" checked={whiteLabelData.some((item) => item.name === 'LA2_BBD_9644_DID')} onChange={(e) => handleCheckboxChange(e, {name:'LA2_BBD_9644_DID', value:9644}, 1)}/></td>
                                                            <td>9644</td>
                                                            <td colSpan="2">LA2_BBD_9644_DID</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" checked={whiteLabelData.some((item) => item.name === 'VOIP_DLR_MIN_LD')} onChange={(e) => handleCheckboxChange(e, {name:'VOIP_DLR_MIN_LD', value:1048}, 1)}/></td>
                                                            <td>1048</td>
                                                            <td colSpan="2">VOIP_DLR_MIN_LD</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" checked={whiteLabelData.some((item) => item.name === 'MN2_BBD_7012_DID')} onChange={(e) => handleCheckboxChange(e, {name:'MN2_BBD_7012_DID', value:7012}, 1)}/></td>
                                                            <td>7012</td>
                                                            <td colSpan="2">MN2_BBD_7012_DID</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" checked={whiteLabelData.some((item) => item.name === 'MN2_BBD_9644_DID')} onChange={(e) => handleCheckboxChange(e, {name:'MN2_BBD_9644_DID', value:9644}, 1)}/></td>
                                                            <td>9644</td>
                                                            <td colSpan="2">MN2_BBD_9644_DID</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" checked={whiteLabelData.some((item) => item.name === 'BBD_9644_DID')} onChange={(e) => handleCheckboxChange(e, {name:'BBD_9644_DID', value:9644}, 1)}/></td>
                                                            <td>9644</td>
                                                            <td>BBD_9644_DID</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" checked={whiteLabelData.some((item) => item.name === 'MIN_BBD_9644_DID')} onChange={(e) => handleCheckboxChange(e, {name:'MIN_BBD_9644_DID', value:9644}, 1)} /></td>
                                                            <td>9644</td>
                                                            <td colSpan="2">MIN_BBD_9644_DID</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>} */}
                                    <div className="col-12">
                                        <input type="submit" value="Update User" className={buttonDisabled ? 'yellow-button disabled-link' : 'yellow-button'}  />
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                
            </div>
        </div>
    )
}
 
export default EditUser