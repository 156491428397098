
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import formatDateTimeToPST from "../PSTDateConvert";
import { useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
 
const DeviceManagement = () => {

    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);    
    const [msg, setMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
   
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [totalItem, setTotalItem] = useState(0);
    const [deviceList, setDeviceList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [carrierList, setCarrierList] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [isAdd, setIsAdd] = useState(false);
    const [access, setAccess] = useState([]);
    const [newCarrier, setNewCarrier] = useState({ group_id : '', group_name: '', platform_type: '', carrier_id : '', number: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [page, setPage] = useState(1);

    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();       
    }, []);

    useEffect(() => {
        if(token){
            getGroupList();
            getCarrierList();
        }
    }, [token])

    useEffect(() => {
        if(role > 2){
            checkAccess();
        }
    }, [role]);

    useEffect(() => {
        getDeviceList(page);
    }, [page]);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const checkAccess = async () => {
        navigate('/dashboard');
    }

    const getDeviceList = async (page) => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/get-cia-carrier_list?page=${page}&limit=${20}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        setDeviceList(response.data.data);
        setTotalItem(response.data.totalItem);
    }

    const getGroupList = async () => {
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/get-all-group-management-list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        setGroupList(response.data.data);
    }

    const getCarrierList = async () => {
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/get-all-carrier-management-list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        setCarrierList(response.data.data);
    }
    const handlePageChange  = (pageNumber) => {
        setPage(pageNumber);
    }

    // Add a new row
    const handleAdd = (e) => {
        e.preventDefault();
        setNewCarrier({ group_id : '', group_name: '', platform_type: '', carrier_id : '', number: '' });
        setErrors({});
        setIsAdd(true);
        setEditIndex(null)
    };

    const handleEdit = (index) => {
        const carrier = deviceList[index];
        setNewCarrier(carrier);
        setIsEditing(true);
        setEditIndex(index);
        setIsAdd(false);
        setErrors({});
    };

    

    // Handle input change for the new row
    const handleInputChange = (e) => {
        if(e.target.name == "group_id"){
            const name = groupList.find((val) => val.id == e.target.value)?.name ?? "";            
            setNewCarrier({ ...newCarrier, [e.target.name]: e.target.value , "group_name" : name});
        }else if(e.target.name == "carrier_id"){
            const carrier_name = carrierList.find((val) => val.id == e.target.value)?.name ?? "";            
            setNewCarrier({ ...newCarrier, [e.target.name]: e.target.value , "name" : carrier_name});
        }else{
            setNewCarrier({ ...newCarrier, [e.target.name]: e.target.name === "number" ? Number(e.target.value) : e.target.value  });
        }
        
    };

    // Save the new row
    const handleDeviceAdd = async (e) => {
        e.preventDefault();

        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
        try {
            setButtonDisabled(true);
            setPopupOpen(true);
            const response = await axios.post(`${baseUrl}/cia-carrier-action`, newCarrier, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setPopupOpen(false);
            setIsSuccess(true);
            setButtonDisabled(false);
            setMsg(response.data.msg);
            if(response.data.status == true){
                window.location.reload();
            }
            
        } catch (error) {
            setButtonDisabled(false);
            setPopupOpen(false);
            if (error.response) {
                setIsSuccess(false);
                setMsg(error.response.data.msg);
            }
        }
    };

    const validate = () => {
        const errors = {};
        // if (!newCarrier.name) {
        //   errors.name = 'Name is required';
        // }
        if (!newCarrier.group_id) {
            errors.group_id = 'Group is required';
        }
        if (!newCarrier.platform_type) {
            errors.platform_type = 'Platform is required';
        }
        if (!newCarrier.carrier_id) {
            errors.carrier_id = 'carrier is required';
        }
        if (!newCarrier.number) {
            errors.number = 'number is required';
        }
        console.log(errors);
        return errors;
    };

    const handleSave = async () => {
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
        let updateObj = newCarrier;
        if (isEditing) {
            // Update carrier
            
            // const { id, name, carrier_index } = newCarrier;
            // updateObj = { id, name, carrier_index };
            
          }
          console.log(updateObj);
          try {
            setButtonDisabled(true);
            setPopupOpen(true);
            const response = await axios.post(`${baseUrl}/cia-carrier-action`,updateObj,{headers: {
                Authorization: `Bearer ${token}`
            }});
            setPopupOpen(false);
            if(response.data.status == false){
                alert(response.data.msg);
                setButtonDisabled(false);
                return;
            }else{
                window.location.reload();
            }
           
        } catch (error) {
            setButtonDisabled(false);
            setPopupOpen(false);
           
        }
          setErrors({});
    }

    const handleActiveCarrier = async (event, index, id) => {
        const { name, checked } = event.target;

        setDeviceList((prevSetting) => {
            const updatedFormData = [...prevSetting];
            updatedFormData[index] = {
                ...updatedFormData[index], // Spread the existing fields of the object
                [name]: checked ? 1 : 0,  // Update the specific field based on 'checked' value
            };
            return updatedFormData;
        });
        console.log(name, checked);
        const action = checked ? 1 : 0;
        // const shouldDelete = window.confirm("Are you sure you want to enable this carrier?");
        // if (shouldDelete) {
            try {
                
                await axios.get(`${baseUrl}/cia-carrier-action/${id}?action=${action}`,{headers: {
                    Authorization: `Bearer ${token}`
                }});
                window.location.reload();
            } catch (error) {
                console.log(error);
            }
        // }
    }
    
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    return (
        <div className="panelbox">
            <Navbar token={token} access={access}/>
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                    <Loader /> 
                ) }
                <div className="content-page admin-attendance-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Device Management</h1>
                            </div>
                        </div>
                        <div className="col-6 inventory-btn">
                            <div className="addnew-button">
                                <Link to="/enrms-management"><span>&larr;</span> Back</Link>
                                {role <= 2 && <a href="#" onClick={handleAdd}>Add</a>}
                            </div>
                            
                        </div>
                    </div>
                    { isAdd && 
                            <div className="common-white-shadow-background setting-section">
                                 <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p>
                                <div className="row">
                                    <div className="col-sm-2" >
                                        <select name="group_id" value={newCarrier.group_id} onChange={handleInputChange}>
                                                    <option value="">Select Group</option>
                                                    {groupList.length > 0 && groupList.map((comp, index) => ( 
                                                        <option key={`${comp.id}-${index}`} value={comp.id}>{comp.name}</option>  
                                                    ))}
                                                </select>
                                        {errors.group_id && <div className="error error-message">Please select group.</div>}
                                    </div>
                                    <div className="col-sm-2" >
                                        <select name="platform_type" value={newCarrier.platform_type} onChange={handleInputChange}>
                                                    <option value="">Select platform</option>
                                                    <option value="1">Android</option>
                                                    <option value="2">iOS</option>
                                                </select>
                                        {errors.platform_type && <div className="error error-message">Please select platform.</div>}
                                    </div>
                                    <div className="col-sm-2" >
                                        <select name="carrier_id" value={newCarrier.carrier_id} onChange={handleInputChange}>
                                                    <option value="">Select carrier</option>
                                                    {carrierList.length > 0 && carrierList.map((comp, index) => ( 
                                                        <option key={`${comp.id}-${index}`} value={comp.id}>{comp.name}</option>  
                                                    ))}
                                                   
                                                </select>
                                        {errors.carrier_id && <div className="error error-message">Please select carrier.</div>}
                                    </div>
                                    <div className="col-sm-2" >
                                    <input
                                        type="number"
                                        name="number"
                                        value={newCarrier.number}
                                        onChange={handleInputChange}
                                    />
                                        {errors.number && <div className="error error-message">Please enter number.</div>}
                                    </div>
                                    <div className="col-sm-2 boxinput company-search" >
                                        <a href="#" className={buttonDisabled ? 'disabled-link search-btn clear-btn' : 'search-btn clear-btn'}  onClick={handleDeviceAdd} >Save</a>
                                    </div>
                                </div>
                            </div>}
                    <div className="common-white-shadow-background setting-section">
                   
                       
            
        
                            <div className="setting-section haft-whitebox">
                                <table className="tablebox search-result-table inventory-table device-management-table">
                                    <thead>
                                    <tr>
                                        <th>Group</th>
                                        <th>Platform Type</th>
                                        <th>Carrier</th>
                                        <th>Destination Number</th>
                                        <th>App - Last Active (UTC)</th>
                                        <th>App Version</th>
                                        <th>Broadcast Status</th>
                                        <th>Status</th>
                                        {role <= 2 && <th><span className='success'>Enable</span>/<span className='error'>Disable</span></th>}
                                        {role < 2 && <th>Edit</th>}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {deviceList.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>
                                                {editIndex === index ? <>
                                                <select name="group_id" value={newCarrier.group_id} onChange={handleInputChange}>
                                                    <option value="">Select Group</option>
                                                    {groupList.length > 0 && groupList.map((comp, index) => ( 
                                                        <option key={`${comp.id}-${index}`} value={comp.id}>{comp.name}</option>  
                                                    ))}
                                                </select>
                                                {errors.group_id && <div className="error error-message">Please select group.</div>}
                                                </> : (item.group_name ?? "-")}
                                            </td>
                                            <td>{item.platform_type == 1 ? "Android" : "IOS" }</td>
                                            <td>{item.name }</td>
                                           
                                            <td>
                                                {editIndex === index ? 
                                                    <>
                                                    <input
                                                        type="number"
                                                        name="number"
                                                        placeholder="Number"
                                                        value={newCarrier.number}
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.name && <div className="error error-message">Please enter Number.</div>}</> : 
                                                    item.number
                                                }
                                            </td>
                                            <td>{formatDateTimeToPST(item.last_active, "UTC", role)}</td>
                                            <td>{item.app_version}</td>
                                            <td>
                                                <span className={item.broadcast_status == 1 ? 'success' : (item.broadcast_status == 2 ? 'error' : '')}>
                                                    {item.broadcast_status == 1 ? 'ON' : (item.broadcast_status == 2 ? "OFF" : "-")}
                                                </span>
                                            </td>
                                            <td><spam className={item.device_status === 'Online' ? 'success' : 'error'}>{item.device_status}</spam></td>
                                            {role <= 2 &&<td>
                                            <div className="switchbtn">
                                                            <input type="checkbox" id={`action-${index}`}  className=" checkbox"  disabled={role >= 2}  checked={item.status == 1} name="status" onChange={(e) => handleActiveCarrier(e, index, item.id)} /> <label
                                                                htmlFor={`action-${index}`} className="toggle">
                                                                <p>
                                                                </p>
                                                            </label>
                                                        </div>
                                            </td>}
                                            {role < 2 && <td>
                                            {editIndex === index && <a href="#" className={buttonDisabled ? 'disabled-link' : ''}  onClick={handleSave} ><img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="Active" title="Use this option to enable customer" /></a>}
                                                {item.status == 1 && <a onClick={() => handleEdit(index)}><img src={`${process.env.PUBLIC_URL}/images/bx-edit.png`} alt="Edit" title="Use this option to edit user" /></a>}
                                                {/* {item.status == 1 &&<a  onClick={() => disableCarrier(item.id)}><img src={`${process.env.PUBLIC_URL}/images/bx-x-circle.png`} alt="Active" title="Use this option to disable user" /></a>}
                                                {item.status == 0 && <a onClick={() => handleActiveCarrier(item.id)}><img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="Edit" title="Use this option to enable user" /></a>} */}
                                            </td>}
                                            
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                            </div>
                        </div>
                </div>
        
            </div>
            </div>
    )
}
 
export default DeviceManagement
