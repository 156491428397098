/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Pagination from "react-js-pagination";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';
import formatDateTimeToPST from "../PSTDateConvert";
import Loader from "../Loader";
import DatePicker from 'react-datepicker';

const CasDeviceApiLog = () => {
    const location = useLocation();
    const abx = location.state;
    // console.log(abx);
    const [name, setName] = useState('');
    const [timeZone, seTimeZone] = useState('');
    const [cid, seCid] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [deviceLog, setDeviceLog] = useState([]);
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [searchValue, setSearchValue] = useState(abx != null ? abx.searchValue : "");
    const [selectedCarrier, setSelectedCarrier] = useState(abx != null ? abx.selectedCarrier : "");
    const [status, setStatus] = useState(abx != null ? abx.status : "0");
  
    const [popupOpen, setPopupOpen] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [displayDate, setDisplayDate] = useState("");
    const [searchDate, setSearchDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
        getOrder(1,'', '', '','','');
        //getCarrierList();
    }, []);
    
    useEffect(() => {
        if(access.length > 0 && token ){
            checkAccess(access, token);
        }
    }, [access, token]);
    
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setName(decoded.name);
            seTimeZone(decoded.timezone);
            setRole(decoded.role)
            setExpire(decoded.exp);
            seCid(decoded.cid);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }
   
    const handlePageChange  = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
        console.log(searchValue);
        console.log(selectedCarrier);
        console.log(searchDate);
        getOrder(pageNumber,searchValue, selectedCarrier, searchDate,startTime,endTime);
    }
    const getOrder = async (page, searchValue, selectedCarrier, searchDate,startTime,endTime) => {
        console.log(page);
        console.log(searchValue);
        console.log(selectedCarrier);
        console.log(searchDate);
        if ((startTime && !searchDate) || (endTime && !searchDate)) {
            alert("Please select date.");
        } else if ((startTime && endTime) && startTime >= endTime) {
            alert("Start time must be earlier than end time.");
        } else {
            setPopupOpen(true);
            const response = await axiosJWT.get(`${baseUrl}/cia-device-api-call-log?page=${page}&limit=${20}&search=${searchValue}&carrier=${selectedCarrier}&date=${searchDate}&start_time=${startTime}&end_time=${endTime}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setPopupOpen(false);
            console.log(response.data);
        
            const result = response.data.data;
        
            setDeviceLog(result);
            setTotalItem(response.data.totalItem);
        }
    }

   

    const checkAccess = async (access) => {
        if(role > 2){
            navigate('/enrms-management');
        }
    }
    

    const handleCarrierChange = (e) => {
        setSelectedCarrier(e.target.value);
       
    };


    const clearFilter = () => {
        setStatus("");
        //setSelectedCompany(0);
        setSelectedCarrier("");
        // setSelectedType("");
        // setSelectedRoute('');
        setSearchValue('');
        setSearchDate('');
        setDisplayDate('');
        setStartTime('');
        setEndTime('');
        //setSelectedEnrmsType("");
        setPage(1);
        //setSelectedItems([]);
        getOrder(1,0,'', '','','');
        setIsFilter(false);
    }

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };
    const handleSearchSubmit = () => {
        setPage(1);
        //setSelectedItems([]);
        console.log(searchValue);
        console.log(selectedCarrier);
        console.log(searchDate);
        getOrder(1,searchValue, selectedCarrier, searchDate,startTime,endTime);
        // getUsers(1, selectedCompany, status, searchValue);
    };

    const handleSearchDate = (date) => { 
        const formattedDate =
        date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
         date.getDate().toString().padStart(2, '0');
         console.log(formattedDate);
         setSearchDate(formattedDate);
        setDisplayDate(date);
    };

 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setName(decoded.name);
            seTimeZone(decoded.timezone);
            setRole(decoded.role)
            setExpire(decoded.exp);
            seCid(decoded.cid);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

   

    
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page admin-attendance-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Device API Log</h1>
                            </div>
                        </div>
                        <div className="col-6 inventory-btn">
                            <div className="addnew-button">
                                <Link to="/enrms-management"><span>&larr;</span> Back</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-9 order-filter">
                            <div className="boxinput">
                            
                                {/* <input className="search-leave satff-listing-page-search" type="text" name="search" placeholder="Search" value={searchValue} onChange={handleSearchChange} />                       */}
                                <select className="form-control" name="usertype" value={selectedCarrier} onChange={handleCarrierChange}>
                                 <option value="" >Carrier</option>                                    
                                 <option value="T-Mobile">T-Mobile</option>
                                 <option value="ATT">ATT</option>
                                 <option value="Verizon">Verizon</option>
                                </select>
                                <DatePicker
                                    selected={displayDate}
                                    onChange={handleSearchDate}
                                    dateFormat="MM/dd/yyyy" // Set the desired date format
                                    placeholderText="Date"
                                    className="from-date"
                                />
                                <input
                                    type="time"
                                    value={startTime}
                                    placeholder="Start Time"
                                    onChange={(e) => setStartTime(e.target.value)}
                                />
                                <input
                                    type="time"
                                    value={endTime}
                                    placeholder="End Time"
                                    onChange={(e) => setEndTime(e.target.value)}
                                />
                                <button  className="search-btn" onClick={handleSearchSubmit}>Search</button>
                               { ( searchValue || selectedCarrier || searchDate) && (<a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>)}
                            </div>
                        </div>
                    </div>
                    
                    <div className="common-white-shadow-background setting-section">
                        <div className="row cia-table cia-table-enable-number">
                        <div className=" setting-section haft-whitebox">
                                <table className="tablebox search-result-table inventory-table cia-number-table">
                                    <thead>
                                        <tr>  
                                            <th>ID</th>                                         
                                            <th>Carrier</th>                                            
                                            <th>Telephone Number</th>
                                            <th>Device Phone Number</th>
                                            <th>App Version</th>
                                            <th>Is Internet</th>
                                            <th>Is Broadcast</th>
                                            <th>API Call Status</th>
                                            <th>Process Status</th>
                                            <th>Caller Number Verification Status</th>
                                            <th>API Resonse Code</th>
                                            <th>Image Capture Status</th>
                                            <th>Image Upload Response</th>
                                            <th>Call Timestamp</th>
                                            <th>Image Upload Timestamp</th>
                                            <th>Local Call Timestamp</th>
                                            <th>Device Model</th>
                                            <th>Last Image Timestamp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {deviceLog.map((item, index) => (
                                        <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.carrier}</td>
                                        <td>{item.tn_number}</td>
                                        <td>{item.device_phone_no}</td>
                                        <td>{item.app_version}</td>
                                        <td> 
                                        {item.is_internet === 1 
                                        ? 'ON' 
                                        : item.is_internet === 0 
                                        ? 'OFF' 
                                        : item.is_internet === null 
                                        ? '-' 
                                        : '-'}
                                        </td>
                                        <td>
                                        {item.is_broadcast === 1 
                                        ? 'Yes' 
                                        : item.is_broadcast === 0 
                                        ? 'No' 
                                        : item.is_broadcast === null 
                                        ? '-' 
                                        : '-'}
                                        </td>
                                        <td>{item.api_call_status}</td>
                                        <td>{item.process_status}</td>
                                        <td>
                                        {item.caller_number_verification_status === 1 
                                        ? 'Verified' 
                                        : item.caller_number_verification_status === 0 
                                        ? 'Unverified' 
                                        : item.caller_number_verification_status === null 
                                        ? '-' 
                                        : '-'}
                                        </td>
                                        <td>{item.api_response_code}</td>
                                        <td>
                                        {item.image_capture_status === 1 
                                        ? 'Yes' 
                                        : item.image_capture_status === 0 
                                        ? 'No' 
                                        : item.image_capture_status === null 
                                        ? '-' 
                                        : '-'}
                                        </td>
                                        <td>{item.image_upload_response}</td>
                                        <td>{item.call_time_stamp}</td>
                                        <td>{item.image_upload_time_stamp}</td>
                                        <td>{item.local_call_time_stamp}</td>
                                        <td>{item.device_model}</td>
                                        <td>{item.last_image_time_stamp}</td>
                                        </tr>))}
                                         </tbody>
                                </table>
                                     
                            </div>
                            
                        </div>
                        {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
 
export default CasDeviceApiLog