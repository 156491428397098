/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Pagination from "react-js-pagination";
import formatDateTimeToPST from "../PSTDateConvert";
import Loader from "../Loader";
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
 
const ViewTrunkDetail = () => {
    const { id } = useParams();
    const [name, setName] = useState('');
    const [timeZone, seTimeZone] = useState('');
    const [cid, setCid] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [orders, setOrders] = useState([]);
    const [policy, setPolicy] = useState('');
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [detail, setDetail] = useState([]);
    const [dmtList, setDmtList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [numberDetail, setNumberDetail] = useState([]);
    const [trunkDetail, setTrunkDetail] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [ciaAccess,setCiaAccess] = useState(false)
    const location = useLocation();
    const abx = location.state;
    console.log(abx);
    
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
        getTrunkDetail(1);
    }, []);

    useEffect(() => {
        if(cid){
            getCustomerDigitMappingAccess();
        }
    }, [cid]);
    // useEffect(() => {
    //     getTrunkDetail(page);
    // }, [page]);

    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
    }, [access, token]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setName(decoded.name);
            seTimeZone(decoded.timezone);
            setCid(decoded.cid);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const getCustomerDigitMappingAccess = async () => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });

            if(response.data.cia_enable_switch === 1){
                if((role > 2 && response.data.cia_account_type == 1) || (role <= 2 && response.data.cia_account_type == 0)){
                    setCiaAccess(true);
                }else{
                    setCiaAccess(false);
                }
            }else{
                setCiaAccess(false);
            }
        } catch (error) {
            // setLoading(false);
            
           console.log(error);
            
        }
    };

    const handlePageChange  = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
        console.log(pageNumber);
    }
    const getTrunkDetail = async (page) => {

        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/trunk-detail/${atob(id)}?page=${page}&limit=${20}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data);
        setPopupOpen(false);
        setNumberDetail(response.data.number_detail);
        setTrunkDetail(response.data.trunk_detail);
        setPolicy(response.data.number_detail[0].policy);
        setDmtList(response.data.dmtList);
        
    }
    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {
        if(!hasPermission('ordernewnumber')){
            navigate('/dashboard');
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setCid(decoded.cid);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    const handleNavigation = (e, id) => {
        e.preventDefault();
        
        navigate(`/inventory`, { state: abx });
    };

    const handleRemoveNumber = async(e) => {
        e.preventDefault();
        const userConfirmed = window.confirm(`Are you sure you want to remove this number from the inventory`);
        if (userConfirmed) {
           
            const data = {
                numberList : [{tn : numberDetail[0].tn_number}],
                cid: numberDetail[0].cid 
            }
            setPopupOpen(true);
            const response = await axiosJWT.post(`${baseUrl}/portal-number-remove`,data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setPopupOpen(false);
            navigate(`/inventory`)
        }
        
    }

    const handleCheckName = async(e) => {
        e.preventDefault();
        setPopupOpen(true);
        const response = await axiosJWT.post(`${baseUrl}/check-cnam-date`, {numberDetail : numberDetail[0]}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        getTrunkDetail(1);
        setPopupOpen(false);
    }

    const handleCiaTypeCommon = async (e,ciaValue) => {
        e.preventDefault();
        const tnNumbers = [numberDetail[0]?.tn_number];
        const type = 1;
        const company_id = cid;
        
        const formData = {tnNumbers,ciaValue, type, company_id}

        try{
            const response = await axios.post(`${baseUrl}/update-cas-type/`,{formData : formData}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }      
            });

            if(response.status == 200){
                navigate(`/inventory`, { state: abx });
            }

        }catch(error){
            console.log(error)
        }
    }
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page">
                    <div className="row ">
                        <div className="col-8">
                            <div className="left-title">
                                <h1>Number Details</h1>
                            </div>
                        </div>
                        <div className="col-4 inventory-btn">
                            <div className="addnew-button">
                               {/* <Link to="/inventory"><span>&larr;</span> Back</Link> */}
                               <a href="#" onClick={(e) => handleNavigation(e)}><span>&larr;</span>Back</a>
                               {abx.status != 2 && <Link to={`/edit-trunk-detail/${id}`}>Edit</Link>}
                               {ciaAccess && numberDetail[0]?.cas_type === 0 && <a href="#" className='newrow' style={{marginLeft: '10px',paddingBottom: '15px'}} onClick={(e)=>{handleCiaTypeCommon(e,true)}}>Enable E-NRMS</a>}
                               {ciaAccess && numberDetail[0]?.cas_type === 1 && <a href="#" className='newrow' style={{marginLeft: '10px',paddingBottom: '15px'}} onClick={(e)=>{handleCiaTypeCommon(e,false)}}>Disable E-NRMS</a>}
                               {abx.carrier_name && abx.status != 2 && numberDetail[0] && <a className="search" href="#" onClick={(e) => handleCheckName(e)} style={{marginLeft: '10px',paddingBottom: '15px'}}>Check CNAM</a>}
                               {/* <div style={{marginLeft: '10px'}}> */}
                                <a className="newrow "  style={{marginLeft: '10px',paddingBottom: '15px'}} onClick={handleRemoveNumber} href="#">Remove</a>
                                {/* </div> */}
                            </div>
                        </div>
                       
                    </div>
                    <div className="row order-detail">
                        <div className="col-3">
                        <div><strong>Tn Number:</strong> {numberDetail[0] ? numberDetail[0].tn_number : ''}</div>
                        <div><strong>Company Name:</strong> {numberDetail[0] ? numberDetail[0].cname : ''}</div>
                        {ciaAccess && <div><strong>E-NRMS Type:</strong> {numberDetail[0] ? numberDetail[0].cas_type === 1 ? 'Enabled' : 'Disabled' : ''}</div>}
                            
                            
                        </div>
                        {abx.status != 2 && <div className="col-3">
                        
                        {numberDetail[0]  && <div><strong>CNAM Label:</strong> {numberDetail[0].cname_label ? numberDetail[0].cname_label : "-"}</div>}
                        {numberDetail[0] && <div><strong>CNAM Date ({timeZone}):</strong> {numberDetail[0].cname_lastcheck ? formatDateTimeToPST(numberDetail[0].cname_lastcheck, timeZone, role) : "-"}</div>}
                        
                            
                        </div>}
                        {abx.status != 2 && <div className="col-3">
                        
                        <div><strong>Status:</strong>{numberDetail[0] ? (numberDetail[0].status == 0 ? "Active" : (numberDetail[0].status == 1 ? "Cancelled" : "Pending")): ''}</div>
                        {numberDetail[0] && numberDetail[0].cancel_date && <div><strong>Cancel Date ({timeZone}) :</strong>{numberDetail[0] && numberDetail[0].cancel_date ? formatDateTimeToPST(numberDetail[0].cancel_date, timeZone, role) : ''}</div>}
                        { trunkDetail.length > 0 && <div><strong>Policy:</strong> {policy == 3 ? 'round_robin' : (policy == 2 ? 'percentage' :'top_down')}</div>}

                            
                        </div>}
                        <div className="col-3">
                        
                        <div><strong>Created Date ({timeZone}) :</strong> {numberDetail[0] ? formatDateTimeToPST(numberDetail[0].created_at, timeZone, role) : ''}</div>
                        <div><strong>Created By:</strong> {numberDetail[0] ? `${numberDetail[0].fname} ${numberDetail[0].lname}` : ''}</div>

                            
                        </div>
                        
                    </div>
                    
                    {abx.status != 2 && <div className="common-white-shadow-background setting-section">
                        <div className="row">
                        <div className=" setting-section haft-whitebox">
                            <div className="search-result-section view-trunk">
                                <h3>Trunk Details</h3>
                                
                            </div>
                            <div className="search-result-section view-trunk">
                                
                                
                            </div>
                                <table className="tablebox search-result-table">
                                    <thead>
                                        <tr>
                                            <th>Route</th>
                                            
                                            <th>Percentage</th>
                                            
                                            <th>Continuous Route</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {trunkDetail.map((trunk, index) => (
                                        <tr key={index}>
                                        <td>{trunk.customer_name}</td>
                                        <td>{trunk.percentage}</td>
                                        <td>{trunk.continuous_route == 0 ? 'No' : 'Yes'}</td>
                                        
                                       
                                        </tr>))}
                                        
                                         </tbody>
                                </table>
                                
                            </div>
                        </div>
                    </div>}
                    
                    {dmtList && dmtList?.length > 0 && <div className="common-white-shadow-background setting-section">
                        <div className="search-result-section"><h3>Associated BAP DMT</h3></div>
                        <table className="tablebox search-result-table">
                            <thead>
                                <tr>
                                    <th>Alias</th>
                                    <th>Origination ANI</th>
                                    <th>Match Length</th>
                                    <th>Origination DNIS</th>
                                    <th>Match Length</th>
                                    <th>Translated ANI</th>
                                    <th>Translated DNIS</th>
                                    <th>ANI Action</th>
                                    <th>DNIS Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dmtList?.map((mapping, index) => (
                                    <tr key={mapping.id}>
                                        <td>{mapping.alias}</td>
                                        <td>{mapping.original_ani}</td>
                                        <td>{mapping.ani_min_length} - {mapping.ani_max_length}</td>
                                        <td>{mapping.original_dnis}</td>
                                        <td>{mapping.dnis_min_length} - {mapping.dnis_max_length}</td>
                                        <td>{mapping.translated_ani}</td>
                                        <td>{mapping.translated_dnis}</td>
                                        <td>{mapping.ani_action == 0 ? "all" : (mapping.ani_action == 2 ? "passthrough" : (mapping.ani_action == 3 ? "prepend" : "match"))}</td>
                                        <td>{mapping.dnis_action == 0 ? "all" : (mapping.dnis_action == 2 ? "passthrough" : (mapping.dnis_action == 3 ? "noLNP" : (mapping.dnis_action == 4 ? "prepend" : "match")))}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {/* <div className="row order-detail">
                        <div className="col-4">
                            <div><strong>Status:</strong> {detail.message}</div>                            
                        </div>
                        <div className="col-4">
                           <div><strong>Status Code:</strong> {detail.status_code}</div>
                           
                        </div>
                       
                    </div> */}
                </div>
            </div>
        </div>
    )
}
 
export default ViewTrunkDetail