import React, { useEffect, useState } from 'react'
import axios from 'axios';
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import DatePicker from 'react-datepicker';
import formatDateTimeToPST from "../../PSTDateConvert";
import {reportTypes} from "../../config";
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';

const CompanyCiaDetail = ({baseData, token}) => {
    const { id } = useParams();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [groupList, setGroupList] = useState([]);
    const [groupScheduleList, setGroupScheduleList] = useState([]);
    const [msg, setMsg] = useState('');
    const navigate = useNavigate();
    const [formData,setFormData] = useState({
        cia_id: null,
        cas_type: null,
        switch_action : null,
        cia_enable_switch: false,
        cia_new_number_switch: false,
        cia_new_order_switch: false,
        cia_account_type:'',
        group_id: '',
        cia_report_type: '',
        occurance_type: 1,
        display_date: null,
        format_date: null,
        one_off_time: null,
        recurring_type:null,
        day_of_week:null,
        day_of_month:null,
        every_x_day: null,
        recurring_time:null,
        start_date : null,
        start_date_display : null,
        start_time: null
    });

    const [showModal,setShowModal] = useState(false);

    useEffect(() => {
        if(baseData){
            setFormData((prevData) => ({
                ...prevData,
                cia_id: baseData?.cia_id,
                cas_type : baseData.cas_type,
                cia_enable_switch:baseData.cia_enable_switch,
                cia_new_number_switch : baseData.cia_new_number_switch,
                cia_new_order_switch : baseData.cia_new_order_switch,
                cia_account_type: baseData?.cia_account_type ? baseData.cia_account_type : '0',
                group_id : baseData?.group_id,
                cia_report_type: baseData?.cia_report_type ? baseData.cia_report_type : 1,
                occurance_type: baseData?.occurance_type ?? 1,
                display_date:baseData?.display_date ?? "",
                format_date:baseData?.format_date ?? "",
                one_off_time:baseData?.one_off_time ?? null,
                recurring_type:baseData?.recurring_type ?? null,
                day_of_week:baseData?.day_of_week ?? null,
                day_of_month:baseData?.day_of_month ?? null,
                every_x_day: baseData?.every_x_day ?? null,
                recurring_time:baseData?.recurring_time ?? null,
                start_date : baseData?.start_date ?? null,
                start_date_display : baseData?.start_date_display ?? null,
                start_time : baseData?.start_time ?? null
            }));
        }
        
    }, [baseData]);

    useEffect(() => {
        if(token){
            getGroupList()
        }
    }, [token]);

    useEffect(() => {
        if(formData.group_id){
            getGroupScheduleList();
        }
    }, [formData.group_id]);

    const dayNameDisplay = (day) => {
        if(day === 1){
            return "Monday";
        }else if(day === 2){
            return "Tuesday";
        }else if(day === 3){
            return "Wednesday";
        }else if(day === 4){
            return "Thursday";
        }else if(day === 5){
            return "Friday";
        }else if(day === 6){
            return "Saturday";
        }else if(day === 7){
            return "Sunday";
        }
    }

    const getGroupList = async () => {
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/get-all-group-management-list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        setGroupList(response.data.data);
    }

    const getGroupScheduleList = async () => {
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/get-group-schedule-list/${formData.group_id}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        console.log(response.data.data);
        
        const scheduleList = response.data.data.map((item) => {
            let description = '';
            if (item.cia_enable_switch === 1) {
                if (item.occurance_type === 1) {
                    description = `Runs ${(formatDateTimeToPST(item.format_date, "UTC", 2)).split(" ")[0]} ${item.one_off_time}`;
                }else if (item.occurance_type === 2) {
                    if (item.recurring_type === 1) {
                        description = `Runs everyday`;
                        if (item.recurring_time) {
                          description += ` at ${item.recurring_time}`;
                        }
                    }else if (item.recurring_type === 2) {
                        description = `Runs every ${dayNameDisplay(item.day_of_week)}`;
                        if (item.recurring_time) {
                          description += ` at ${item.recurring_time}`;
                        }
                    }else if (item.recurring_type === 3) {
                        // Monthly
                        description = `Runs every  ${item.day_of_month} ${(item.day_of_month == 1 || item.day_of_month == 21) ? 'st' : ((item.day_of_month == 2 || item.day_of_month == 22) ? "nd" : "th")} day of month`;
                        if (item.recurring_time) {
                          description += ` at ${item.recurring_time}`;
                        }
                    } else if (item.recurring_type === 4) {
                        // Every X days
                        description = `Runs every ${item.every_x_day} day`;
                        if (item.recurring_time) {
                          description += ` at ${item.recurring_time}`;
                        }
                    } 
                }
            }
            const obj = {
                cname: item.cname,
                schedule: description
            }
            return obj;
        });
        console.log(scheduleList)
        setGroupScheduleList(scheduleList);
    }

    const handleToggleCiaSwitch = (event) => {
        const { name, checked } = event.target;

        if(checked){
            // if(window.confirm("Are you sure you want to enable E-NRMS access for this customer ?")){
            //     setFormData((prevData) => ({
            //         ...prevData,
            //         [name]: checked,
            //     })); 
            // }
            // onUpdate({...formData,[name] : checked})
            setShowModal(true)
        }else{
            setFormData((prevData) => ({
                ...prevData,
                [name]: checked,
                cia_enable_switch: false, cia_new_number_switch: false, cia_new_order_switch : false
            }));
            // onUpdate({
            //     ...formData,
            //     cia_enable_switch: false, cia_new_number_switch: false, cia_new_order_switch : false})
        }

    }

    const handleToggleCiaDaySwitch = (event) => {
        const { name, checked } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
        // onUpdate({...formData,[name]: checked})

    }

    const handleAccountChange = (event) =>{
        const {name, value} = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        // onUpdate({...formData,[name] : value})
    }

    // const handleScheduleChange = (event) => {
    //     const { name, value } = event.target;

    //     if(value === 'regular_default_schedule'){
    //         setFormData((prevData) => ({
    //             ...prevData,
    //             [name]: value,
    //             cia_schedule_hours:0,
    //             cia_schedule_days:null,
    //         }));
    //         onUpdate({...formData,[name] : value,cia_schedule_hours:0, cia_schedule_days: null})
    //     }else if(value === 'manual_selection'){
    //         setFormData((prevData) => ({
    //             ...prevData,
    //             [name]: value,
    //             cia_schedule_hours:0,
    //             cia_schedule_days:null,
    //         }));
    //         setManualSelection('cia_schedule_hours')
    //         onUpdate({...formData,[name] : value,cia_schedule_hours:0, cia_schedule_days: null})
    //     }
    // }
    
    const accountTypes = [
        { value: "0", label: "BBD Only"},
        { value: "1", label: "BBD & Customer" },
    ];
    const weekDayList = [
        { label:"Sun", value: 1},
        { label: "Mon", value: 2 },
        { label: "Tue", value: 3 },
        { label: "Wed", value: 4 },
        { label: "Thu", value: 5 },
        { label: "Fri", value: 6 },
        { label: "Sat", value: 7 },
    ];
    const monthDayList = Array.from({ length: 30 }, (_, index) => ({
        label: (index + 1).toString(),
        value: index + 1
    }));

    // const handleHoursChange = (event) => {
    //     setFormData((prevData)=> ({...prevData, cia_schedule_hours: event.target.value}))
    //     onUpdate({...formData,cia_schedule_hours : event.target.value, cia_schedule_days: null})

    // }

    // const handleDaysChange = (event) => {
    //     setFormData((prevData) => ({...prevData, cia_schedule_days: event.target.value}))
    //     onUpdate({...formData, cia_schedule_days : event.target.value, cia_schedule_hours: null})
    // }

    // const handleManualSelection = (e) => {
    //     setManualSelection(e.target.value)
    //     if(e.target.value === 'cia_schedule_hours'){
    //         onUpdate({...formData,cia_schedule_hours : formData.cia_schedule_hours, cia_schedule_days: null})
    //     }else{
    //         onUpdate({...formData, cia_schedule_days : formData.cia_schedule_days, cia_schedule_hours: null})
    //     }
    // }

    const handleRadioChange = (e) => {
        const {name, value} = e.target;

        setFormData((prevData) => ({...prevData, [name]: value}));
        // onUpdate({...formData,[name] : value})
    };


    const handleSearchDate = (date) => { 
       console.log(date);
        const formattedDate =
        date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
         date.getDate().toString().padStart(2, '0');
         console.log(formattedDate);
        
        setFormData((prevData) => ({...prevData, display_date: date, format_date: formattedDate}))
        // onUpdate({...formData,display_date: date, format_date: formattedDate})
    };

    // const handleInputChange = (e) => {
    //     let value = e.target.value.replace(/\D/g, '');
    //     const name = e.target.name;

    //     // console.log(name, value);
    //     if (value.length > 4) {
    //         value = value.slice(0, 4); // Limit to 4 digits (hhmm)
    //     }
    //     if (value.length >= 3) {
    //         value = value.slice(0, 2) + ':' + value.slice(2); // Add colon after 2 digits
    //     } else if (value.length >= 2) {
    //         value = value.slice(0, 2) + ':' + value.slice(2);
    //     }
    //     setFormData((prevData) => ({...prevData, [name]: value}))
    // };
    const handleInputChange = (e) => {
        let value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        const name = e.target.name;
    
        // Ensure the value is within the proper length
        if (value.length > 4) {
            value = value.slice(0, 4); // Limit to 4 digits (hhmm)
        }
    
        // Format the value as hh:mm
        if (value.length >= 3) {
            let hours = value.slice(0, 2);
            let minutes = value.slice(2);
    
            // Ensure hours are in the range 00-23
            if (parseInt(hours) > 23) {
                hours = '23';
            }
    
            // Ensure minutes are in the range 00-59
            if (parseInt(minutes) > 59) {
                minutes = '59';
            }
    
            value = hours + ':' + minutes;
        } else if (value.length >= 2) {
            let hours = value.slice(0, 2);
            let minutes = value.slice(2);
    
            // Ensure hours are in the range 00-23
            if (parseInt(hours) > 23) {
                hours = '23';
            }
    
            value = hours + (minutes ? ':' + minutes : ':');
        }
    
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        // onUpdate({...formData,[name] : value})
    };

    const handleDateChange = (date) => {
        const formattedDate =
        date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
         date.getDate().toString().padStart(2, '0');
         console.log(formattedDate);
        
        setFormData((prevData) => ({...prevData, start_date_display: date, start_date: formattedDate}))
        // onUpdate({...formData,start_date_display: date, start_date: formattedDate})
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Backspace') {
            let value = e.target.value;
            if (value.endsWith(':')) {
                value = value.slice(0, -1);
            }
            const name = e.target.name;
            value = value.replace(/\D/g, ''); // Remove non-digit characters
    
            setFormData((prevData) => ({ ...prevData, [name]: value }));
            // onUpdate({...formData,[name] : value})
        }
    };
    
    const closeModal = ()=>{
        setShowModal(false)
    }
    
    const handleModalConfirmation = (value)=>{
        setFormData((prevData) => ({
                    ...prevData,
                    cas_type: value,
                    cia_enable_switch: true,
                    switch_action:true
                })); 
        // onUpdate({...formData, cas_type: value, cia_enable_switch: true, switch_action:true})
        setShowModal(false)
    }
    
    const handleApplyConfiguration = async(e, type) => {
        e.preventDefault();
        if (formData.recurring_type == 2 && (formData.day_of_week == null || formData.day_of_week == '' || formData.day_of_week == 0)) {
            alert("Please select a day of week in CAS enabled number");
            return
        };
        if (formData.recurring_type == 3 && (formData.day_of_month == null || formData.day_of_month == '' || formData.day_of_month == 0)) {
            alert("Please select a day of month in CAS enabled number");
            return
        };
        if (formData.recurring_type == 4 && (formData.every_x_day == null || formData.every_x_day == '' || formData.every_x_day == 0)) {
            alert("Please enter valid value in CAS enabled number");
            return
        };
        if(formData.cia_report_type != 2 && (formData.group_id == null || formData.group_id == '')){
            alert("Please select groups");
            return
        }
        const combinedData = {
            formData: formData,
            type: type
        }
        try {
            setButtonDisabled(true);
            setPopupOpen(true);
            const response = await axios.post(`${baseUrl}/user-company/enrms-config/${id}`, combinedData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setIsSuccess(true);
            setPopupOpen(false);
            setMsg(response.data.msg);
            window.location.reload();
        } catch (error) {
            setButtonDisabled(false);
            setPopupOpen(false);
            if (error.response) {
                setIsSuccess(false);
                setMsg(error.response.data.msg);
            }
        }
    }

  return (
    <div className="common-white-shadow-background setting-section">
        {popupOpen && (
                    <Loader />
                )}
        <Modal isOpen={showModal} onRequestClose={closeModal} className="cia-model">
            <div className='row'>
                
                <div className='col-sm-3 cancel-btn'><button onClick={closeModal}>Cancel</button></div>
                
                <div className='col-sm-9 apply-all'>
                    <button className="apply-btn" onClick={()=>{handleModalConfirmation(false)}}>No</button>
                    <button className="apply-btn" onClick={()=>{handleModalConfirmation(true)}}>Yes</button>
                </div>
                
            </div>
            <div className="policy">
                <p>Do you want to apply E-NRMS functionality to all existing numbers ?</p>
                </div>
        </Modal>
        <div className="innerboxwidth">
            <div className="row">
                <div className="header-row">
                    <h3>E-NRMS Configuration</h3>
                </div>
                <div className="settingbox">
                    <label>E-NRMS Access</label>
                    <div className="switchbtn">
                        <input type="checkbox" id="cia_enable_switch" className="checkbox" checked={formData.cia_enable_switch === true} name="cia_enable_switch" onChange={handleToggleCiaSwitch} /> 
                        <label htmlFor="cia_enable_switch" className="toggle">
                            <p></p>
                        </label>
                    </div>
                </div>
                
                {formData.cia_enable_switch && <>
                <div className="settingbox">
                    <label>Prompt for E-NRMS while ordering new number</label>
                    <div className="switchbtn">
                        <input type="checkbox" id="cia_new_order_switch" className="checkbox" checked={formData.cia_new_order_switch === true} name="cia_new_order_switch" onChange={handleToggleCiaDaySwitch} /> 
                        <label htmlFor="cia_new_order_switch" className="toggle">
                            <p></p>
                        </label>
                    </div>
                </div>
                <div className="settingbox">
                    <label>Apply To All New Numbers</label>
                    <div className="switchbtn">
                        <input type="checkbox" id="cia_new_number_switch" className="checkbox" checked={formData.cia_new_number_switch === true} name="cia_new_number_switch" onChange={handleToggleCiaDaySwitch} /> 
                        <label htmlFor="cia_new_number_switch" className="toggle">
                            <p></p>
                        </label>
                    </div>
                </div>
                
                <div className="settingbox">
                    <label>Report Type</label>
                    <div  className='cia-wrap'>
                        {<select name="cia_report_type" id="cia_report_type" value={formData.cia_report_type} onChange={handleAccountChange}>
                            {reportTypes.map((report) => (
                                    <option key={report.value} value={report.value}>
                                    {report.label}
                                    </option>
                                ))}
                        </select> }
                    </div>
                </div>
                
                <div className="settingbox">
                    <label>E-NRMS Access Type</label>
                    <div  className='cia-wrap'>
                        {<select name="cia_account_type" id="cia_account_type" value={formData.cia_account_type} onChange={handleAccountChange}>
                            {accountTypes.map((account) => (
                                    <option key={account.value} value={account.value}>
                                    {account.label}
                                    </option>
                                ))}
                        </select> }
                    </div>
                </div>

                <div className="settingbox">
                    <label>Device Group</label>
                    <div  className='cia-wrap'>
                        {<select name="group_id" id="group_id" value={formData.group_id} onChange={handleAccountChange}>
                            <option value="">Select Group</option>
                            {groupList.map((account) => (
                                    <option key={account.id} value={account.id}>
                                    {account.name}
                                    </option>
                                ))}
                        </select> }
                    </div>
                </div>
           
               
                { groupScheduleList.length > 0 &&<div className="row schedule_pattern">
                    <h3 className='comapny-label'>Schedules List</h3>
                    <div className="settingbox radio-standard-box">
                    <table className='group-schedule-list'>
                        <thead>
                            <tr>
                                <th>Company</th>
                                <th>Schedule (UTC)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupScheduleList.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.cname}</td>
                                    <td>{item.schedule}</td>
                                </tr>
                            ))}
                            
                        </tbody>
                    </table>
                    </div>
                </div>  }            
                <div className="row schedule_pattern">
                    <h3 className='comapny-label'>E-NRMS Calling Schedule</h3>
                    {/* <label>Occurance</label> */}
                    <div className="settingbox radio-standard-box">
                        <label htmlFor="one_off_schedual"><input type="radio" id="one_off_schedual" name="occurance_type" value={1} checked={formData.occurance_type == 1} onChange={handleRadioChange} />
                        One-off schedule</label>          

                             <label for="recurring_schedual"><input type="radio" id="recurring_schedual" name="occurance_type" value={2} checked={formData.occurance_type == 2} onChange={handleRadioChange} />
                             Recurring schedule</label>
                        
                    </div>
                </div>
                {formData.occurance_type == 1 && <div className="row one_off_schedual">
                    <b>Date and time (UTC)</b>
                    <span>The date and time to invoke the target.</span>
                    <div className="col-md-12 order-filter transection-filter">
                        <div className="boxinput">
                            <div>
                                <DatePicker
                                    selected={formData.display_date}
                                    onChange={handleSearchDate}
                                    dateFormat="MM/dd/yyyy" // Set the desired date format
                                    placeholderText="MM/DD/YYYY"
                                    className="from-date"
                                />
                                {/* <p>MM/DD/YYYY</p> */}
                            </div>
                            <div>
                                <input className="one_off_time" name="one_off_time" type="text" maxLength="5" placeholder="hh:mm" value={formData.one_off_time}  onKeyDown={handleKeyDown}  onChange={handleInputChange} />
                                {/* <p>Use 24-hour format timestamp (hh:mm)</p> */}
                            </div>
                        </div>
                    </div>                    
                </div>}

                {formData.occurance_type == 2 && <div className="row schedule_pattern">
                   
                    <div className="row cron_schedual">
                        {/* <b>Cron expression</b>
                        <span>Define the cron expression for the schedule.</span> */}
                        <div className="settingbox">
                            <label>Recurring Type</label>
                            <div className='recurring_type'>
                                <select name="recurring_type" id="recurring_type" value={formData.recurring_type} onChange={handleRadioChange}>
                                    <option value="1">EveryDay</option>
                                    <option value="2">Day of week</option>
                                    <option value="3">Day of month</option>
                                    <option value="4">Every X Day</option>
                                </select>
                            </div>
                            {formData.recurring_type == 2 &&<div className='cia-wrap'>
                                <select name="day_of_week" id="day_of_week" value={formData.day_of_week} onChange={handleRadioChange}>
                                    <option value="">Select a Day</option>
                                    {weekDayList.map((week) => (
                                        <option key={week.value} value={week.value}>{week.label}</option>
                                    ))}
                                </select>
                            </div>}
                            {formData.recurring_type == 3 &&<div className='cia-wrap'>
                                <select name="day_of_month" id="day_of_month" value={formData.day_of_month} onChange={handleRadioChange}>
                                    <option value="">Select a Day</option>
                                    {monthDayList.map((month) => (
                                        <option key={month.value} value={month.value}>{month.label}</option>
                                    ))}
                                </select>
                            </div>}
                            {formData.recurring_type == 4 &&<div className='cia-wrap'>
                                <input type="number" name="every_x_day" id="every_x_day" placeholder="X Days" value={formData.every_x_day} onChange={handleRadioChange}  />
                            </div>}
                        </div>
                        <div className="settingbox">
                            <label>Time (UTC)</label>
                            <div className='cia-wrap'>
                                <input className="recurring_time" name="recurring_time" type="text" maxLength="5" placeholder="hh:mm" value={formData.recurring_time}  onKeyDown={handleKeyDown}  onChange={handleInputChange} />
                            </div> 
                        </div>
                    </div>
                    {/* <div className='row cron_schedual'>
                        <div className="settingbox">
                            <label>Start Date & Time (UTC)</label>
                            <div className='recurring_type'>
                            <DatePicker
                                    selected={formData.start_date_display}
                                    onChange={handleDateChange}
                                    dateFormat="MM/dd/yyyy" // Set the desired date format
                                    placeholderText="MM/DD/YYYY"
                                    className="from-date"
                                />
                            </div>
                            <div className='cia-wrap'>
                                <input className="recurring_time" name="start_time" type="text" maxLength="5" placeholder="hh:mm" value={formData.start_time}  onKeyDown={handleKeyDown}  onChange={handleInputChange} />
                            </div> 
                        </div>
                    </div> */}
                </div>}
                
                <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p>
               

               

                {/* <div className="settingbox">
                    <label>Schedule pattern</label>
                    <div className='cia-wrap'>
                        <select name="cia_schedule_type" id="cia_schedule_type" value={formData.cia_schedule_type} onChange={handleScheduleChange}>
                            <option value="regular_default_schedule">Regular</option>
                            <option value="manual_selection">Manual</option>
                        </select>
                    </div>
                    
                </div> */}
                {/* <div className="settingbox">
                    <label>Schedule Type</label>
                    <div className='cia-wrap'>
                        <select name="cia_schedule_type" id="cia_schedule_type" value={formData.cia_schedule_type} onChange={handleScheduleChange}>
                            <option value="regular_default_schedule">Regular</option>
                            <option value="manual_selection">Manual</option>
                        </select>
                    </div>
                    
                </div> */}
                {/* {formData.cia_schedule_type === 'regular_default_schedule' && 
                    <div className="settingbox">
                        <label></label>
                        <div className='cia-schedule-wrap cia-wrap'>
                            <div>
                                <label className="hours-label" htmlFor="cia_schedule_hours">Hours</label>
                            </div>
                            <div className='daybox'>
                                <input type="number" name="cia_schedule_hours" value={formData.cia_schedule_hours} onChange={handleHoursChange} />
                            </div>
                        </div>
                            
                    </div>
                } */}
                
                {/* {formData.cia_schedule_type === 'manual_selection' && <div className="settingbox">
                    <label></label>
                    <div className='cia-schedule-wrap cia-wrap'>
                        <div>
                            <select name="cia_schedule_manual_selection" id="cia_schedule_manual_selection" value={manualSelection} onChange={handleManualSelection}>
                                <option value="cia_schedule_hours">Hours</option>
                                <option value="cia_schedule_days">Days</option>
                            </select>
                        </div> 
                        {<div className='daybox'>
                        {manualSelection === 'cia_schedule_hours' &&  <input type="number" name="cia_schedule_hours" value={formData.cia_schedule_hours} onChange={handleHoursChange} />}
                        {manualSelection === 'cia_schedule_days' &&  <input type="number" name="cia_schedule_days" value={formData.cia_schedule_days} onChange={handleDaysChange} />}
                    </div>}       
                    </div>
                    
                </div>} */}
                </>}
                <div className='row schedule_pattern'>
                    <div className='row cron_schedual'>
                        <div className="settingbox">
                        <div className="addnew-button">
                            <a href="#" className={buttonDisabled ? 'disabled-link' : ''} style={{paddingBottom: '15px'}}  onClick={(e) => {handleApplyConfiguration(e, 1)}} >Apply E-NRMS Configuration</a>
                            <a href="#" className={buttonDisabled ? 'disabled-link' : ''} style={{marginLeft: '10px',paddingBottom: '15px'}} onClick={(e) => {handleApplyConfiguration(e, 2)}}  >Clear All Schedules</a>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CompanyCiaDetail