/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import { useNavigate, Link } from 'react-router-dom';
import Modal from 'react-modal';

const Step2 = ({ token, formData, setFormData  }) => {
   
    const navigate = useNavigate();
    
    const [popupOpen, setPopupOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentItem, setCurrentItem] = useState({}); 
    const [validationErrors, setValidationErrors] = useState([]);
    const [selectedPolicy, setSelectedPolicy] = useState("");
    const [trunkRouteName, setTrunkRouteName] = useState([]);
    const [orderNumberType, setOrderNumberType] = useState(0);
    const [selectedMobile, setSelectedMobile] = useState(null);
    const [dataArray, setDataArray] = useState([]);
    const [routingList, setRoutingList] = useState([]);
    const [nullMessge, setNullMessge] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [tableData, setTableData] = useState( [
        {
          selectedOption: '',
          inputText: '',
          yesNo: '',
          
        },
        {
          selectedOption: '',
          inputText: '',
          yesNo: '',
          
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
            
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
            
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
           
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
           
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
           
        }
    ]);
    
    
    const openModal = (item) => {
        setShowModal(true);
        setCurrentItem(item);
    };
    const closeModal = () => {
        setShowModal(false);
        setValidationErrors([]);
    };
    
    useEffect(() => {
        if(token){
            getTrunkName();
            getRoutingOption();
        }
    }, [token]);

    // =========  based on number list check number type DID/TFN ============
    useEffect(() => {
        const counts = formData.importNumberList.reduce((acc, curr) => {
            acc[curr.number_type] = (acc[curr.number_type] || 0) + 1;
            return acc;
        }, {});
        const result = counts[0] && counts[1] ? 2 : counts[0] ? 0 : 1;
        setOrderNumberType(result);
    }, [formData.importNumberList]);

    useEffect(() => {
        
        console.log(formData.routePath, "change");
    }, [formData.routePath]);

    // =========  based on selected number validation for single number ============
    useEffect(() => {
        // Validate formData.routePath whenever it changes
        if (formData.routePath && formData.routePath.length > 0) {
            
            const errors = validateFormData(formData);
            setValidationErrors(errors);
          
            if (selectedMobile) {           
                const filteredErrorsArray = errors.filter(error => error.includes(selectedMobile));
                setValidationErrors(filteredErrorsArray);
        
                setShowModal(filteredErrorsArray.length > 0);
            }else {        
                setValidationErrors(errors);
                setShowModal(errors.length > 0);        
            }
        }
    }, [formData.routePath, selectedMobile]);

    // =========  based on selected number set route_option, route_id , route_name for single number ============
    useEffect(() => {       
        if(formData.routePath && formData.routePath.length > 0 && selectedMobile){

            const tableData = formData.routePath.find((item) => item[selectedMobile]?.tableData) ?. [selectedMobile]?.tableData || null;
            // console.log(tableData);
            if(tableData){
                console.log(tableData);
                // const targetValue =  tableData[0].selectedOption;
                // const selected = trunkRouteName.find((item) => item.value.includes(targetValue))?.name;
               
                
     
                const selectedRoutes = tableData.filter((val) => val.selectedOption != "")
                    .map((item) => {
                        // Find the name in trunkRouteName based on the selectedOption in tableData
                        const selected = trunkRouteName.find((route) => route.value.includes(item.selectedOption))?.name;
                    
                        // Find the route in routingList based on the selected name
                        const routeDetails = routingList.find((element) => element.value === selected);
                    
                        // Return an object with value, name, and id if routeDetails exists
                        return routeDetails
                            ? {
                                value: routeDetails.value,
                                name: routeDetails.name,
                                id: routeDetails.id
                            }
                            : null; // If not found, return null
                    }); 

                const route_option = selectedRoutes.map((val) => val.value).join(",");
                const route_name = selectedRoutes.map((val) => val.name).join(",");
                const route_id = selectedRoutes.map((val) => val.id).join(",");
                
                const index = formData.importNumberList.findIndex((item) => item.tn_number == selectedMobile);
                if (index !== -1) {
                    // Update the existing item
                    formData.importNumberList[index] = {
                        ...formData.importNumberList[index],
                        route_option,
                        route_name,
                        route_id,
                    };
                } else {
                    // Add a new item if the tn_number is not found
                    formData.importNumberList.push({
                        tn_number: selectedMobile,
                        route_option,
                        route_name,
                        route_id,
                    });
                }
            }
        }
      
    },[formData.routePath,selectedMobile]);

    // =========  set tabledata and policy based on selected number , if not assign then display blank ============
    useEffect(() => {
        if (formData.routePath && currentItem.tn_number) {
            // Check if currentItem.telephoneNumber is in formData.routePath
            const foundItem = formData.routePath.find(
                (item) => Object.keys(item)[0] == currentItem.tn_number
            );
    
            // Set selectedPolicy based on the check
            setSelectedPolicy(foundItem ? foundItem[currentItem.tn_number].policy : '');
            setTableData(foundItem ? foundItem[currentItem.tn_number].tableData : [
                {
                  selectedOption: '',
                  inputText: '',
                  yesNo: '',
                  
                },
                {
                  selectedOption: '',
                  inputText: '',
                  yesNo: '',
                  
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                }
            ]);
        }

    }, [formData.routePath, currentItem.tn_number]);

    // =========  check trunk assign for number and if assign then consider validtrunk ============
    useEffect(() => {
        
        formData.importNumberList.forEach(item => {
            const phoneNumber = item.tn_number;
            const matchingRoute = formData.routePath && formData.routePath.find(route => route.hasOwnProperty(phoneNumber));
           
            if (!matchingRoute) {
                item.validTrunk = false;
              return;
            }
        
            const routeData = matchingRoute[phoneNumber];
            
            if (routeData.policy == "1") {
                
                item.validTrunk = routeData.tableData.some(item => item.selectedOption !== "");
            } else if (routeData.policy === "2") {
              const hasSelectedOption = routeData.tableData.some(item => item.selectedOption !== "");
              const hasPercentageValues = routeData.tableData.some(item => item.inputText !== "" && Number(item.inputText) > 0);
              const sumOfPercentageValues = routeData.tableData.reduce((sum, item) => sum + Number(item.inputText), 0);

                item.validTrunk = hasSelectedOption && hasPercentageValues && sumOfPercentageValues === 100;
            } else {
                
                item.validTrunk = false;
            }
          });
         
    },[formData.routePath, formData.importNumberList]);

    // =========  check bandwidh did / bandwidh toll free route assign for customer or not ============
    useEffect(() => {
        if(routingList.length > 0 && formData.importNumberList){
       
        const hasNullValue = routingList.some(obj => obj.bandwidth_did === null || obj.bandwidth_toll_free === null);
            const checkNumberType = formData.importNumberList.some(item => item.number_type == 1);
            if(hasNullValue && checkNumberType){
                setNullMessge(true);
            }else{
                setNullMessge(false);
            }
        }
    }, [formData.importNumberList, routingList])

    const getTrunkName = async () => {
        
            const counts = formData.importNumberList && formData.importNumberList.reduce((acc, curr) => {
                acc[curr.number_type] = (acc[curr.number_type] || 0) + 1;
                return acc;
            }, {});
            const result = counts[0] && counts[1] ? 2 : counts[0] ? 0 : 1;
        const response = await axios.get(`${baseUrl}/get-trunk-name/${formData.company_id}?type=${result}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }       
        });
        const allArray = response.data;
        
        setTrunkRouteName(allArray.data);
        
    }
    const getRoutingOption = async () => {
        try {
            
            const counts = formData.importNumberList.reduce((acc, curr) => {
                acc[curr.number_type] = (acc[curr.number_type] || 0) + 1;
                return acc;
            }, {});
            const result = counts[0] && counts[1] ? 2 : counts[0] ? 0 : 1;
            setPopupOpen(true);
            const response = await axios.get(`${baseUrl}/route-list/${formData.company_id}?type=${result}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            const allArray = response.data;
        if(allArray.statusCode == 200){
            setRoutingList(allArray.data);
          
        }
        setPopupOpen(false);
          
        } catch (error) {
            setPopupOpen(false);
           console.log(error);
            
        }
    };

    const handleRowChange = (e, index, fieldName) => {
        const newValue = e.target.value;
      
        setTableData((prevData) => {
            const updatedData = prevData.map((row, rowIndex) => {
                if (rowIndex === index) {
                    return {
                        ...row,
                        [fieldName]: newValue
                      
                    };
                }
                return row;
            });
            return updatedData;
        });
    };

    const handleSave = () => {

        const newData = {
            [currentItem.tn_number]: { policy: selectedPolicy, tableData: tableData },
        };

        // Add the object to the dataArray
        const indexToUpdate = dataArray.findIndex(
            (data) => data.hasOwnProperty(currentItem.tn_number)
        );

        // If an object with the same telephone number exists, update it
        if (indexToUpdate !== -1) {
            const updatedArray = [...dataArray];
            updatedArray[indexToUpdate] = newData;
            setDataArray(updatedArray);
            setFormData({ ...formData, routePath: updatedArray });
        } else {
            // If it doesn't exist, add the new data
            setDataArray((prevDataArray) => {
                const updatedArray = [...prevDataArray, newData];
                setFormData({ ...formData, routePath: updatedArray });
                return updatedArray;
              });
        }
        setSelectedMobile(currentItem.tn_number);
        
    };
    const handleDefaultSet = () => {
        const confirmed = window.confirm("Are you sure you want to apply this to all?");
        if(confirmed){

            const updatedDataArray = [...dataArray];

            formData.importNumberList.forEach((item) => {
                const newData = {
                    [item.tn_number]: { policy: selectedPolicy, tableData: tableData },
                };
            
                const indexToUpdate = updatedDataArray.findIndex((data) =>
                    data.hasOwnProperty(item.tn_number)
                );
            
                if (indexToUpdate !== -1) {
                    // If the number exists in dataArray, update it
                    updatedDataArray[indexToUpdate] = newData;
                } else {
                    // If the number doesn't exist, add it to the array
                    updatedDataArray.push(newData);
                }
            });

            const updatedImportNumberList = formData.importNumberList.map((item) => {
                // Extract selected routes from tableData
                const selectedRoutes = tableData
                    .filter((val) => val.selectedOption !== "")
                    .map((tableItem) => {
                        const selected = trunkRouteName.find((route) =>
                            route.value.includes(tableItem.selectedOption)
                        )?.name;
        
                        const routeDetails = routingList.find(
                            (element) => element.value === selected
                        );
        
                        return routeDetails
                            ? {
                                  value: routeDetails.value,
                                  name: routeDetails.name,
                                  id: routeDetails.id,
                              }
                            : null;
                    })
                    .filter(Boolean); // Remove null entries
        
                // Create combined route data
                const route_option = selectedRoutes.map((val) => val.value).join(",");
                const route_name = selectedRoutes.map((val) => val.name).join(",");
                const route_id = selectedRoutes.map((val) => val.id).join(",");
        
                // Return updated item with new route details
               
                return {
                    ...item,
                    route_option,
                    route_name,
                    route_id,
                };
            });

            // Update both dataArray and formData.routePath
            setDataArray(updatedDataArray);
            setFormData({ ...formData,importNumberList: updatedImportNumberList, routePath: updatedDataArray });

            // Optionally clear the selected mobile or take another action
            setSelectedMobile(null);
            
        }
            
    }

    const handleUnassignedSet = () => {
        const confirmed = window.confirm("Are you sure you want to apply this to all Unassigen numbers?");
        if(confirmed){
            const updatedDataArray = [...dataArray];

            formData.importNumberList.forEach((item) => {
                if(!item.route_option){
                    const newData = {
                        [item.tn_number]: { policy: selectedPolicy, tableData: tableData },
                    };
                
                    const indexToUpdate = updatedDataArray.findIndex((data) =>
                        data.hasOwnProperty(item.tn_number)
                    );
                
                    if (indexToUpdate !== -1) {
                        // If the number exists in dataArray, update it
                        updatedDataArray[indexToUpdate] = newData;
                    } else {
                        // If the number doesn't exist, add it to the array
                        updatedDataArray.push(newData);
                    }
                }
                
            });

            const updatedImportNumberList = formData.importNumberList.map((item) => {
                if(!item.route_option){
                    // Extract selected routes from tableData
                    const selectedRoutes = tableData
                        .filter((val) => val.selectedOption !== "")
                        .map((tableItem) => {
                            const selected = trunkRouteName.find((route) =>
                                route.value.includes(tableItem.selectedOption)
                            )?.name;
                        
                            const routeDetails = routingList.find(
                                (element) => element.value === selected
                            );
                        
                            return routeDetails
                                ? {
                                      value: routeDetails.value,
                                      name: routeDetails.name,
                                      id: routeDetails.id,
                                  }
                                : null;
                        })
                        .filter(Boolean); // Remove null entries
                    
                    // Create combined route data
                    const route_option = selectedRoutes.map((val) => val.value).join(",");
                    const route_name = selectedRoutes.map((val) => val.name).join(",");
                    const route_id = selectedRoutes.map((val) => val.id).join(",");
                    
                    // Return updated item with new route details
                    
                    return {
                        ...item,
                        route_option,
                        route_name,
                        route_id,
                    };
                }else{
                    return {
                        ...item
                    };
                }
            });

            // Update both dataArray and formData.routePath
            setDataArray(updatedDataArray);
            setFormData({ ...formData,importNumberList: updatedImportNumberList, routePath: updatedDataArray });

            // Optionally clear the selected mobile or take another action
            setSelectedMobile(null);
        }
    }

    // trunks validation for all number
    const validateFormData = (data) => {
        const errors = [];
        console.log(data);
        // Your validation logic here
        if (!data.routePath) {
            // errors.push("Route path is missing.");
            errors.push(`Please Fill proper trunk value for all number`);
        } else {
            data.routePath.forEach(dataItem => {
                const mobileKey = Object.keys(dataItem)[0]; // Get the telephoneNumber from the object key
                const currentMobile = dataItem[mobileKey];
                if (!currentMobile) {
                    errors.push(`Mobile ${mobileKey} not found in the data.`);                
                } else {
                    if (!currentMobile.policy) {
                        errors.push(`Policy field is required for ${mobileKey}.`);            
                    }                
                    const hasTableDataValue = currentMobile.tableData.some(tableItem =>
                        tableItem.selectedOption 
                    );
                    const isValid = currentMobile.tableData.some((tableItem, index) => {
                        if (index < currentMobile.tableData.length - 1) {
                            return tableItem.selectedOption === "" && currentMobile.tableData[index + 1].selectedOption !== "";
                        }
                        return false;
                    });
                    if (!hasTableDataValue) {
                        errors.push(`Atleast one route is required for ${mobileKey}.`);
                    } else if(isValid) {
                        errors.push(`Please select proper trunk for ${mobileKey}.`);
                    }else if (currentMobile.policy === "2") {
                        const inputTextValues = currentMobile.tableData.map(tableItem => Number(tableItem.inputText));
                        const hasUserInput = inputTextValues.some(value => value > 0);    
                        if (!hasUserInput) {
                            errors.push(`Percentage values for ${mobileKey} are required.`);
                        } else {
                            const sumOfInputTextValues = inputTextValues.reduce((acc, value) => acc + value, 0);    
                            if (sumOfInputTextValues !== 100) {
                                errors.push(`Sum of percentage for ${mobileKey} is not equal to 100.`);
                            }
                        }
                    }
                }
            });
        }
    
        return errors;
    };

    const handleNext = async (e) => {
        e.preventDefault();
       
        const hasBlankRouteOption = (formData.importNumberList && formData.importNumberList.length > 0) ? formData.importNumberList.some(item => item.route_option == '') : true;
        const hasBlankRouteOptionInFormData = formData.importNumberList ? formData.importNumberList.some(item => item.route_option == '') : false;
        const errors = validateFormData(formData);
        console.log(errors);
        const bandwidthList = formData.importNumberList.filter(obj => obj.number_type == 1);
        const firstObj = bandwidthList[0];
        const areAllBandwidthRouteSame = bandwidthList.every(obj => obj.route_option == firstObj.route_option && obj.route_id == firstObj.route_id);        
        if (hasBlankRouteOptionInFormData) {            
            if (hasBlankRouteOption){
                alert("Please assign routing options for all numbers");
            }
            
        }else if(nullMessge){
            alert("There is an issue with the customer configuration which prevents ordering new numbers.  Please contact Broadband Dynamics to have this resolved.");
        }else if (errors.length > 0){
            alert(errors.join('\n'));
            // 
        }else if(!areAllBandwidthRouteSame){
            alert("Due to carrier restrictions, multiple routing options are not allowed on this order.  If you require numbers to have different routing options, please place an order for each set of numbers requiring different routing.")
        }else{
            if(formData.routePath.length != formData.importNumberList.length){
                alert("Please assign routing options for all numbers");
            }else{
                try{
                    setButtonDisabled(true);
                    setPopupOpen(true);
                    axios.post(`${baseUrl}/import-non-supported-carrier-number`, formData,{
                        headers: {
                             Authorization: `Bearer ${token}`                            
                        }
                    });
                    const allArray = {nonSupportCarrier : true}
                    navigate("/order-new-number-success", { state: allArray });  
                    setPopupOpen(false);
                    setButtonDisabled(false);
                }catch(error){
                    console.log(error)
                    setPopupOpen(false);
                    setButtonDisabled(false);
                }
               
            }
            
        }
    
    };

    

    return (
        <>
            {popupOpen && <Loader /> }
            <div className="content-sub-title">
                <div className="row">
                    <div className='order-queue'>
                        <h2>Tel Numbers and Routing Options</h2>
                        <h3>Tel Numbers: {formData.importNumberList.length}</h3>
                    </div>
                </div>
            </div>
            <div className="common-white-shadow-background setting-section tableinput-section">
                <div className="row footer-row">
                    <div className="footer-row-left">
                    <Link className="Back" to={"/number-management"}>Cancel</Link>
                    </div>
                    <div className="footer-row-right">
                        <a href="#" onClick={handleNext} className={buttonDisabled ? 'next disabled-link' : 'next'}>Submit</a>
                    </div>
                </div>
            </div>
            <div className="common-white-shadow-background setting-section tableinput-section">
                <div className="row">
                    <table className="tablebox new-number-two-setup">
                        <thead>
                            <tr>
                                <th>Tel Number</th>
                                <th>Trunks</th>
                                <th>Routing Option</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formData.importNumberList.map((item,  index) => (
                                <tr key={index} >
                                    <td>{item.tn_number}</td>
                                    <td >{item.validTrunk ? (<img src={`${process.env.PUBLIC_URL}/images/valid-phone.png`} onClick={(e) => openModal(item)} />) : (<img src={`${process.env.PUBLIC_URL}/images/trunk-phone.png`} onClick={(e) => openModal(item)} />)} </td>
                                    <td><p className='green-text'>{item.route_option}</p></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Modal 
                        isOpen={showModal}
                        onRequestClose={closeModal}
                        contentLabel={"Example Modal"}
                    >
                        <div className='row'>
                            <div className='col-sm-3 cancel-btn'><button onClick={closeModal}>Cancel</button></div>
                            <div className='col-sm-9 apply-all'>
                            {orderNumberType != 2 && formData.routePath && formData.routePath.length != 0 && formData.importNumberList && formData.routePath.length != formData.importNumberList.length &&<button className="apply-btn" onClick={handleUnassignedSet}>Apply to Unassigned</button> }
                            {orderNumberType != 2 && <button className="apply-btn" onClick={handleDefaultSet}>Apply to all</button> }
                            <button className="save-btn" onClick={handleSave} >Apply to Number</button>
                            </div>
                        </div>
                        <div className="policy">
                            <label>Policy</label>
                            <div className="switchbtn">
                                <select value={selectedPolicy} onChange={(e) => {setSelectedPolicy(e.target.value)}}>
                                    <option value="">Select Policy</option>
                                    <option value="1">top_down</option>
                                    <option value="2">percentage</option>
                                    <option value="3">round_robin</option>
                                </select>
                            </div>
                        </div>
                        {validationErrors.map((value, index) => (<p style={{"color":"red"}} key={index}>{value}</p>))}
                        <div className='fullwidth-table track-table-body staff-listing-admin-table-body'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Route</th>
                                    <th>Load Percentage</th>
                                    <th>Continuous routing</th>
                                </tr>
                            </thead>
                            <tbody>
                            {tableData.map((row, index) => (
                                <tr key={index}>
                                    <td>
                                        <select value={row.selectedOption} onChange={(e) => handleRowChange(e, index, 'selectedOption')}>            
                                            <option value="" >Select Route</option>    
                                            {trunkRouteName.map((item, index) => (
                                                <option key={index} value={item.value} >
                                                  {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input type="number" value={row.inputText} onChange={(e) => handleRowChange(e, index, 'inputText')} />
                                            <span style={{ marginLeft: '5px', width: '50px', height: '25px' }}>%</span>
                                        </div>
                                    </td>
                                    <td>
                                        <select value={row.yesNo} onChange={(e) => handleRowChange(e, index, 'yesNo')}>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>                              
                                        </select>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}

export default Step2;