
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import formatDateTimeToPST from "../PSTDateConvert";
import {dayNameDisplay, reportTypes} from "../config";
import { useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
 
const ScheduleSummaryList = () => {

    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);    
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedReportType, setSelectedReportType] = useState('');
    const [company, setCompany] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [scheduleList, setScheduleList] = useState([]);
    const [access, setAccess] = useState([]);
    const [page, setPage] = useState(1);

    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();
        getUserCompany();
    }, []);

    useEffect(() => {
        if(role > 2){
            checkAccess();
        }
    }, [role]);

    useEffect(() => {
        getScheduleSummaryList(page, selectedValue, selectedReportType);
    }, [page]);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        }
    }

    const checkAccess = async () => {
        navigate('/dashboard');
    }

    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const allArray = response.data.filter(item => item.id !== 1);
        setCompany(allArray);
    }

    const getScheduleSummaryList = async (page, cid, reportType) => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/enrms-schedule-summary-list?page=${page}&cid=${cid}&reportType=${reportType}&limit=${20}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        setScheduleList(response.data.data);
        setTotalItem(response.data.totalItem);
    }

    const handlePageChange  = (pageNumber) => {
        setPage(pageNumber);
    }
    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);          
    };
    const handleSearchSubmit = () => {
        setPage(1);
        getScheduleSummaryList(1, selectedValue, selectedReportType);

    };

    const clearFilter = () => {
        setPage(1);
        setSelectedValue("");
        setSelectedReportType("");
        getScheduleSummaryList(1, "", "");
        
    };

    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getSheduleTime = (item) => {
        let description= "-";
        if (item.cia_enable_switch === 1) {
            if (item.occurance_type === 1 && item.format_date) {
                description = `Runs ${(formatDateTimeToPST(item.format_date, "UTC", 2)).split(" ")[0]} ${item.one_off_time}`;
            }else if (item.occurance_type === 2) {
                if (item.recurring_type === 1) {
                    description = `Runs everyday`;
                    if (item.recurring_time) {
                      description += ` at ${item.recurring_time}`;
                    }
                }else if (item.recurring_type === 2) {
                    description = `Runs every ${dayNameDisplay(item.day_of_week)}`;
                    if (item.recurring_time) {
                      description += ` at ${item.recurring_time}`;
                    }
                }else if (item.recurring_type === 3) {
                    // Monthly
                    description = `Runs every  ${item.day_of_month} ${(item.day_of_month == 1 || item.day_of_month == 21) ? 'st' : ((item.day_of_month == 2 || item.day_of_month == 22) ? "nd" : "th")} day of month`;
                    if (item.recurring_time) {
                      description += ` at ${item.recurring_time}`;
                    }
                } else if (item.recurring_type === 4) {
                    // Every X days
                    description = `Runs every ${item.every_x_day} day`;
                    if (item.recurring_time) {
                      description += ` at ${item.recurring_time}`;
                    }
                } 
            }
        }
        return description;
    }


    return (
        <div className="panelbox">
            <Navbar token={token} access={access}/>
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                    <Loader /> 
                ) }
                <div className="content-page admin-attendance-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Schedule Summary</h1>
                            </div>
                        </div>
                        <div className="col-6 inventory-btn">
                            <div className="addnew-button">
                                <Link to="/enrms-management"><span>&larr;</span> Back</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 order-filter">
                            <div className="boxinput">                            
                                <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                                    <option value="">Select Company</option>
                                    {company.map((comp) => ( 
                                            <option key={comp.id} value={comp.id}>
                                                {comp.cname}
                                            </option>  
                                        ))}
                                </select>
                                <select className="form-control" name='carrierStatus' value={selectedReportType} onChange={(e)=>{setSelectedReportType(e.target.value)}}>
                                    <option value=''>Select Report Type</option>
                                    {reportTypes.map((report) => (
                                    <option key={report.value} value={report.value}>
                                    {report.label}
                                    </option>
                                ))}
                                        
                                </select>
                                
                                
                                <button  className="search-btn" onClick={handleSearchSubmit}>Search</button>
                               { ( selectedValue || selectedReportType ) && (<a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>)}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 common-white-shadow-background setting-section">
                            <div className="fullwidth-table track-table-body staff-listing-admin-table-body cia-carrier-list">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Company</th>
                                        <th>Report Type</th>
                                        <th>Schedule Time(UTC)</th>
                                        <th>Group Id</th>
                                        <th>Total Number</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {scheduleList.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.cname}</td>
                                            <td>{item.cia_report_type == 1 ? "ENRMS" : (item.cia_report_type == 2 ? "Basic NRMS" : (item.cia_report_type == 3 ? "E-Only NRMS" : "-"))}</td>
                                            <td>{getSheduleTime(item)}</td>
                                            <td>{item.group_name}</td>
                                            <td>{item.total_number}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                            </div>
                        </div>
                </div>
        
            </div>
            </div>
    )
}
 
export default ScheduleSummaryList
