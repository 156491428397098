import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import Navbar from '../Navbar'
import axios from 'axios';
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Header from '../Header';
import { saveAs } from 'file-saver';
import Pagination from "react-js-pagination";
import formatDateTimeToPST from "../PSTDateConvert";
import Popup from './Popup';
import PopupImage from './PopupImage';

const CiaNumberDetails = () => {
    const location = useLocation();
    const { compid, type, carrier,filter, batch_id } = location.state || {};
    const [name, setName] = useState('');
    const [cid, setCid] = useState('')
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [company, setCompany] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [casAccess, setCASAccess] = useState(false);
    const [selectedValue, setSelectedValue] = useState(compid);
    const [filterType, setFilterType] = useState(0);
    const [selectedCarrier,setSelectedCarrier] = useState(carrier ?? "0");
    const [selectedStatus,setSelectedStatus] = useState(type ?? "all");
    const [showNoAccessMessage,setShowNoMessageAccess] = useState(false);
    const [batchDates, setBatchDates] = useState([]);
    const [selectedBatchDate, setSelectedBatchDate] = useState("");
    // const [batchList, setBatchList] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState("");
    const [carrierPage, setCarrierPage] = useState(1);
    const [datePage, setDatePage] = useState(1);
    const[searchFilter, setSearchFilter] = useState(false);
    const[exportData, setExportData] = useState(false);
    const [carrierData, setCarrierData] = useState([]);
    const [carrierTotalItem, setCarrierTotalItem] = useState(0);
    const [dateData, setDateData] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const [dateTotalItem, setDateTotalItem] = useState(0);
    const [isAdvanceFieldChecked, setAdvanceFieldIsChecked] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupData, setPopupData] = useState(null);

    const [isPopupTwoOpen, setIsPopupTwoOpen] = useState(false);
    const [selectedDataTwo, setSelectedDataTwo] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();
        getUserCompany();
    }, []);

    useEffect(() => {
        if(role > 2 && cid){
            setSelectedValue(cid);
        }
    },[role, cid]);

    useEffect(()=>{
        if(selectedValue && token){
            getCustomerCASAccess(); 
            getCiaCallHistoryDate();           
        }
    },[selectedValue, access, token]);

    // useEffect(() => {
    //     if(selectedBatchDate && selectedValue){
    //         getCiaCallHistoryBatchList();
    //     }
    // }, [selectedValue, selectedBatchDate]);

    useEffect(() => {
        if(filter && token && access){
            setFilterType(1);
            setSearchFilter(true);
        }
    }, [filter, token, access]);
    
    useEffect(() => {
        if((searchFilter == true || exportData == true) && filterType == 1 && selectedValue && selectedBatch){
            getCarrierWiseDetail();
        }
    }, [searchFilter, filterType, selectedValue, selectedCarrier, selectedStatus, selectedBatch, selectedBatchDate, carrierPage, exportData]);

    useEffect(() => {
        if((searchFilter == true || exportData == true) && filterType == 2 && selectedValue  && selectedBatch){
            getDateWiseDetail();
        }
    }, [searchFilter, filterType, selectedValue,  selectedBatch, selectedBatchDate, datePage, exportData]);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
            setCid(decoded.cid)
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            setAccess(response.data.results);
            
        } catch (error) {
           console.log(error);
        }
    }

    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const allArray = response.data.filter(item => item.id !== 1);
        setCompany(allArray);
    }
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // setCid(decoded.cid)
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const gotoSwitchScreen = (e) => {
        e.preventDefault();
        const state = { state: {compid : selectedValue}}
        navigate("/cas-management", state);
    }

    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);  
        clearFilter();

    };

    const getCustomerCASAccess = async () => {
        try {
            setPopupOpen(true);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${selectedValue}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            
            if (role > 2 && (response.data.user_enms_access === 0 || response.data.cia_enable_switch === 0 || (response.data.cia_enable_switch === 1 && response.data.cia_account_type!== 1))) {
                navigate('/dashboard');
                return;
            }
            console.log(response.data);
            if(response.data.cia_enable_switch === 1){
                if(response.data.cia_account_type === 0 && role > 2){
                        setCASAccess(false)
                        setShowNoMessageAccess(true)
                }else{
                    setShowNoMessageAccess(false);
                    setCASAccess(true)
                    
                }
            }else{
                setCASAccess(false)
                setShowNoMessageAccess(true)
            }
            setPopupOpen(false);
        } catch (error) {
           console.log(error);
        }
    };

    const handleFilterChange =  (e) => {
        setFilterType(e.target.value);
        setCarrierData([]);
        setCarrierTotalItem(0);
        setDateData([]);
        setDateTotalItem(0);
        handleSearchSubmit();
    }

    const getCiaCallHistoryDate = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/get-cia-call-history-list/${selectedValue}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            setBatchDates(response.data.result);
            if(batch_id){                
                setSelectedBatch(batch_id);
            }else{
                setSelectedBatchDate(response.data.result[0].date);
                setSelectedBatch( response.data.result[0].id);
            }
        } catch (error) {
           console.log(error);
        }
    }

    // const getCiaCallHistoryBatchList = async () => {
    //     try {
    //         const response = await axiosJWT.post(`${baseUrl}/get-cia-call-history-batch/${selectedValue}`, {date: selectedBatchDate, type:1}, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }       
    //         });
           
            
    //         setBatchList(response.data.result);
    //         setSelectedBatch( response.data.result[0].id);
    //     } catch (error) {
    //        console.log(error);
    //     }
    // }

    const handleSearchSubmit = () => {
        
        // console.log(filterType, selectedCarrier, selectedCarrier, selectedBatchDate, selectedBatch)
        setCarrierPage(1);
        setDatePage(1);
        setSearchFilter(true);
        setExportData(false);
        
    };

    const clearFilter = () => {
        setFilterType(0);
        setSearchFilter(false);
        setExportData(false);
        setCarrierData([]);
        setCarrierTotalItem(0);
        setDateData([]);
        setDateTotalItem(0);
        setSelectedCarrier(carrier ?? "0");
        setSelectedStatus(type ?? "all");
    }

    const getCarrierWiseDetail = async () => {
        setPopupOpen(true);
        const carrierOb = {
            page:carrierPage,
            limit: 120,
            carrier: selectedCarrier,
            status: selectedStatus,
            batchDate: selectedBatchDate,
            batch: selectedBatch,
            export: exportData
        };
        const response = await axios.post(`${baseUrl}/get-number-detail-carrier-data/${selectedValue}`,carrierOb,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setSearchFilter(false);
        setExportData(false);
        if(exportData == true){
            exportCarrierData(response.data.data);
        }else{
            setCarrierData(response.data.data);
            setCarrierTotalItem(response.data.totalItem);
            
            
        }
        setPopupOpen(false);
    }

    const getDateWiseDetail = async () => {
        
        setPopupOpen(true);
        const carrierOb = {
            page:datePage,
            limit: 120,
            batchDate: selectedBatchDate,
            batch: selectedBatch,
            export : exportData
        };
        const response = await axios.post(`${baseUrl}/get-number-detail-date-data/${selectedValue}`,carrierOb,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setSearchFilter(false);
        setExportData(false);
        
        if(exportData == true){
            exportDateData(response.data.data, response.data.carriers);
        }else{
            setDateData(response.data.data);
            setDateTotalItem(response.data.totalItem);
            setCarriers(response.data.carriers);
        }
        setPopupOpen(false);
    }

    const handleCheckboxChange = (event) => {
        setAdvanceFieldIsChecked(event.target.checked);
        console.log("Checkbox is checked:", event.target.checked);
        // Add any other logic you want to handle here
    };
    const handleCarrierPageChange = (pageNumber) => {
        setCarrierPage(pageNumber);
        setSearchFilter(true);
        setExportData(false);
    }
    const handleDatePageChange = (pageNumber) => {
        setDatePage(pageNumber);
        setSearchFilter(true);
        setExportData(false);
    }

    const handelOpenPopupTwo = async (e,path) => {
        e.preventDefault();
        const response = await axios.post(`${baseUrl}/get-dialed-number-file`, {path});
        const { presignedUrl } = response.data;
        setSelectedDataTwo(presignedUrl);
        setIsPopupTwoOpen(true);
    };
    const closePopupTwo = () => {
        setIsPopupTwoOpen(false);
        setSelectedDataTwo(null);
    };

    const handelOpenPopup = async (e,tnNumber,date) => {
        e.preventDefault();
        const cid = selectedValue;

        const response = await axios.post(`${baseUrl}/cia-get-ftc-data-by-tn-number`, { tnNumber: tnNumber,cid: cid, date:date });

        setPopupData(response.data.data);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setPopupData(null);
    };

    const handleExportCSV = async (e) => {
        e.preventDefault();
        setSearchFilter(false);
        setExportData(true);
    }

    const exportCarrierData = (list) => {
        const columnList = ["Date","Caller ID", "Carrier", "Stir Shaken Attestation Audit", "CNAM Audit", "Scan Risk Levels", "Scan FTC Report","Caller ID Status (Android) ","Caller ID Status (iOS) "];

        const csvContent = list.map(item => {
            return [
                item.call_date_time,
                item.tn_number,
                item.carrier_name,
                item.attestation_audit,
                item.cnam,
                item.report_type != 3 ? (item.scan_risk_level !== null && item.scan_risk_level !== undefined && item.scan_risk_level !== '' && item.scan_risk_level != "-" ? `Risk level : ${item.scan_risk_level}` : "-") : "-",
                item.report_type != 3 ? (item.ftc_scan_report == 1 ?  formatDateTimeToPST(item.ftc_created_date, "UTC", 2) +"_"+escapeCsvDateValue(item.ftc_subject) : (item.ftc_scan_report == 2 ? "Nothing Reported" : "")) : "-",
                item.report_type != 2 ? item.android_status : "-",
                item.report_type != 2 ? item.ios_status : "-",
            ].join(',');
        }).join('\n');
        const csv = [columnList, csvContent].join('\n');
        const blob = new Blob([csv], { type: 'text/csv' });

        saveAs(blob, 'cia_enable_number.csv');

    }

    const exportDateData = (list, carrier) => {
        let columnList =  ["Caller ID","Date","Stir Shaken Attestation Audit","CNAM Audit","Scan Risk Levels","Scan FTC Report"];
        
                    
        carrier.map((item) => {
            columnList.push(`${item.name} Caller ID Status (Android)`, `${item.name} Caller ID Status (iOS)`);
        });

        const csvContent = list.map(item => {
            let row = [
                item.tn_number,
                item.call_date_time,                
                item.attestation_audit,
                item.cnam,
                item.report_type != 3 ? (item.scan_risk_level !== null && item.scan_risk_level !== undefined && item.scan_risk_level !== ''  && item.scan_risk_level != "-" ? `Risk level : ${item.scan_risk_level}` : "-") : "-",
                item.report_type != 3 ? (item.ftc_scan_report == 1 ? formatDateTimeToPST(item.ftc_created_date, "UTC", 2) +"_"+escapeCsvDateValue(item.ftc_subject) : (item.ftc_scan_report == 2 ? "Nothing Reported" : "")) : "-"
                
            ];
            carriers.map((val) => {
                row.push(item.report_type != 2 ? item[`android_status_carrier_${val.id}`] : "-");
                row.push(item.report_type != 2 ? item[`ios_status_carrier_${val.id}`] : "-");
            });
            return row.join(',');
        }).join('\n');
        
        const csv = [columnList, csvContent].join('\n');
        const blob = new Blob([csv], { type: 'text/csv' });

        saveAs(blob, 'cia_enable_number.csv');
                    
    }

    return (
        <div className="panelbox">
            <Navbar token={token} access={access}/>
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                    <Loader /> 
                ) }
                <div className="content-page admin-attendance-page">
                    <div className="row">
                        <div className="col-8">
                            <div className="left-title">
                                <h1>E-NRMS Number Details</h1>
                            </div>
                        </div>
                        <div className="col-4 inventory-btn">                      
                            <div className="addnew-button">
                            <a href="#" onClick={(e) => gotoSwitchScreen(e)}>Back</a>
                            </div>
                        </div>
                    </div>
                    {(role <= 2) && <div className="common-white-shadow-background setting-section">
                        <div className="row">
                            <h3 className='comapny-label'>Select Company</h3>
                            <div className="inputbox">
                                <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                                    <option value="">Select Company</option>
                                    {company.map((comp) => ( 
                                            <option key={comp.id} value={comp.id}>
                                                {comp.cname}
                                            </option>  
                                        ))}
                                </select>
                            </div>
                        </div>
                    </div>}
                    {showNoAccessMessage && 
                    <div className="common-white-shadow-background setting-section">
                        <div className="row error">You don't have access to the features.</div>
                    </div>}

                    {casAccess && <>
                        <div className='common-white-shadow-background setting-section'>
                            <div className='row'>
                                <div className='col-10'>
                                    <div className='boxinput'>
                                        <select className="form-control" name='filter_type' value={filterType} onChange={(e)=>{handleFilterChange(e)}}>
                                            <option value='0'>Select a Carrier or Date</option>
                                            <option value='1'>By Carrier</option>
                                            <option value='2'>By Date</option>
                                        </select>
                                        {filterType == 1 && <select className="form-control" name='carriertype' value={selectedCarrier} onChange={(e)=>{setSelectedCarrier(e.target.value)}}>
                                            <option value='0'>All</option>
                                            <option value='1'>T-Mobile</option>
                                            <option value='2'>ATT</option>
                                            <option value='3'>Verizon</option>
                                        </select>}
                                        {filterType == 1 && <select className="form-control" name='carrierStatus' value={selectedStatus} onChange={(e)=>{setSelectedStatus(e.target.value)}}>
                                            <option value='all'>All</option>
                                            <option value='2'>Normal</option>
                                            <option value='1'>Spam</option>
                                            <option value='3'>Unknown</option>

                                        </select>}
                                        {(filterType == 1 || filterType == 2) && <select className="form-control" name='carrierStatus' value={selectedBatch}  onChange={(e) => {
      const selectedOption = e.target.options[e.target.selectedIndex];
      setSelectedBatch(e.target.value); // Set the value
      setSelectedBatchDate(selectedOption.getAttribute('data-date')); // Set the data-id
    }}>
                                          {batchDates && batchDates.map((item) => (
                                            <option value={item.id} data-date={item.date}>{item.id} - {item.date.split(" ")[0]}</option>
                                          ))}
                                        </select>}
                                        {/* {(filterType == 1 || filterType == 2) && <select className="form-control" name='carrierStatus' value={selectedBatch} onChange={(e)=>{setSelectedBatch(e.target.value)}}>
                                          {batchList && batchList.map((item) => (
                                            <option value={item.id}>{item.id}</option>
                                          ))}
                                        </select>} */}
                                      
                                      
                                      
                                        {(filterType == 1 || filterType == 2) && <button className="search-btn" style={{marginLeft:'10px'}} onClick={handleSearchSubmit}>Search</button>}
                                        {(filterType == 1 || filterType == 2) && selectedCarrier && <a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>}
                                    </div>
                                </div>
                                <div className="col-2 inventory-btn">
                                    <div className="addnew-button">
                                        {(carrierData.length > 0 || dateData.length > 0) && <a className="newrow" onClick={handleExportCSV} href="#">Export</a>}
                                    </div>
                                </div>
                                {((filterType == 1 || filterType == 2) && role <= 2)  && <div className="row">
                                <div className='advance-settingbox'>
                                    <div className="advance_checkbox switchbtn pull-right">
                                        <label>Show All Info &nbsp;&nbsp;</label>
                                        <input 
                                            type="checkbox" 
                                            id={`advanceFieldCheckbox`}  
                                            className="checkbox" 
                                            name="status" 
                                            checked={isAdvanceFieldChecked}
                                            onChange={handleCheckboxChange} // Change event handler
                                        /> 
                                        <label htmlFor={`advanceFieldCheckbox`} className="toggle">
                                        </label>
                                        </div>
                                    </div>
                                </div>}
                                <div className="row">
                                    
                                    {filterType == 1 && <CarrierWiseData carrierData={carrierData} isAdvanceFieldChecked={isAdvanceFieldChecked} carrierPage={carrierPage} carrierTotalItem={carrierTotalItem} carrierPageChange={handleCarrierPageChange} openPopupTwo={handelOpenPopupTwo} openPopup={handelOpenPopup} role={role} />}
                                    {filterType == 2 && <DateWiseData dateData={dateData} carriers={carriers} isAdvanceFieldChecked={isAdvanceFieldChecked} datePage={datePage} dateTotalItem={dateTotalItem} datePageChange={handleDatePageChange} openPopupTwo={handelOpenPopupTwo} openPopup={handelOpenPopup} role={role} />}
                                   
                                </div>
                                {isPopupOpen && <Popup data={popupData} onClose={closePopup} />}
                                {isPopupTwoOpen && <PopupImage data={selectedDataTwo} onClose={closePopupTwo} />}
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    );

}

const escapeCsvDateValue = (value) => {
    if (typeof value === 'string') {
        // Escape existing double quotes by doubling them
        value = value.replace(/"/g, '""');
        // Wrap the value in double quotes if it contains commas or quotes
        if (value.includes(',') || value.includes('"')) {
            return `"${value}"`;
        }
    }
    return value;
};



const CarrierWiseData = ({carrierData, isAdvanceFieldChecked, carrierPage, carrierTotalItem, carrierPageChange, openPopupTwo, openPopup, role}) => {
    
    const scanFTCName = (name, subject, tn_number, date, created_date) => {
        if(name == 1){
            return <a  href="#" onClick={(e) => openPopup(e,tn_number, date)}>{formatDateTimeToPST(created_date, "UTC", "2") + "_"+ escapeCsvDateValue(subject)}</a>;
        }else if(name == 2){
            return "Nothing Reported"
        }else{
            return "";
        }        
    }
    const getStatusName = (name, path) => {
        if(name == "Normal" || name == "Spam"){
            return  <a  href="#" onClick={(e) => openPopupTwo(e,path)}>{name}</a>;
        }else if(role <= 2 && name == "Unknown" && path != null && path != ""){            
            return  <a  href="#" onClick={(e) => openPopupTwo(e,path)}>{name}</a>;
        }else{
            return name;
        }
    }

    const handlePageChange  = (pageNumber) => {
        carrierPageChange(pageNumber);
    }
    return (
        <>
        <div className="setting-section haft-whitebox cia-table cia-table-enable-number">
        <table className={`tablebox search-result-table inventory-table cia-number-table carrier-table`}>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Caller ID</th>
                    <th>Carrier</th>
                    <th>Stir Shaken Attestation Audit</th>
                    <th>CNAM Audit</th>
                    <th>Scan Risk Levels</th>
                    <th>Scan FTC Report</th>
                    <th>Caller ID Status (Android) </th>
                    {isAdvanceFieldChecked && <th>App Status (Andriod)</th>}
                    {isAdvanceFieldChecked && <th>Carrier Info. (Andriod)</th>}
                    {isAdvanceFieldChecked && <th>OCR (Andriod)</th>}
                    <th>Caller ID Status (iOS) </th>
                    {isAdvanceFieldChecked && <th>App Status (iOS)</th>}
                    {isAdvanceFieldChecked && <th>OCR (iOS)</th>}
                    
                </tr>
            </thead>
            <tbody>
                {carrierData && carrierData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        <td>{row.call_date_time}</td>
                        <td>{row.tn_number}</td>
                        <td>{row.carrier_name}</td>
                        <td>{row.attestation_audit}</td>
                        <td>{row.cnam}</td>
                        <td>{row.report_type != 3 ? (row.scan_risk_level != null && row.scan_risk_level !== '' && row.scan_risk_level != "-" ? `Risk level :  ${row.scan_risk_level}` : "-") : "-"}</td>
                        <td>{row.report_type != 3 ? scanFTCName(row.ftc_scan_report, row.ftc_subject,row.tn_number, row.call_date, row.ftc_created_date) :"-"}</td>
                        <td>{row.report_type != 2 ? getStatusName(row.android_status, row.screenshot_android) : "-"}</td>
                        {isAdvanceFieldChecked && <td>{row.report_type != 2 ?  row.device_status_android : "-"}</td>}
                        {isAdvanceFieldChecked && <td>{row.report_type != 2 ?  row.carrier_info_android : "-"}</td>}
                        {isAdvanceFieldChecked && <td>{row.report_type != 2 ?  row.screenshot_text_android : "-"}</td>}
                        <td>{row.report_type != 2 ? getStatusName(row.ios_status, row.screenshot_ios) : "-"}</td>
                        {isAdvanceFieldChecked && <td>{row.report_type != 2 ? row.device_status_ios : "-"}</td>}
                        {isAdvanceFieldChecked && <td>{row.report_type != 2 ? row.screenshot_text_ios : "-"}</td>}
                    </tr>
                ))}
            </tbody>
        </table>
        {/* {isPopupOpen && <Popup data={popupData} onClose={closePopup} />}
        {isPopupTwoOpen && <PopupImage data={selectedDataTwo} onClose={closePopupTwo} />}    */}
        </div>
         {carrierTotalItem > 120 && (<Pagination
            activePage={carrierPage}
            itemsCountPerPage={120}
            totalItemsCount={carrierTotalItem}
            pageRangeDisplayed={5}
            onChange={handlePageChange.bind(this)}
          />)}
        </>
    )
}

const DateWiseData = ({dateData, carriers, isAdvanceFieldChecked, datePage, dateTotalItem, datePageChange, openPopupTwo, openPopup, role}) => {
    
    const scanFTCName = (name, subject, tn_number, date, created_date) => {
        if(name == 1){
            return <a  href="#" onClick={(e) => openPopup(e,tn_number, date)}>{formatDateTimeToPST(created_date, "UTC", 2) + "_"+escapeCsvDateValue(subject)}</a>;
        }else if(name == 2){
            return "Nothing Reported"
        }else{
            return "";
        }        
    }
    const getStatusName = (name, path) => {
        if(name == "Normal" || name == "Spam"){
            return  <a  href="#" onClick={(e) => openPopupTwo(e,path)}>{name}</a>;
        }else if(role <= 2 && name == "Unknown" && path != null && path != "" && path != "-"){
            return  <a  href="#" onClick={(e) => openPopupTwo(e,path)}>{name}</a>;
        }else{
            return name;
        }
    }

    const handlePageChange  = (pageNumber) => {
        datePageChange(pageNumber);
    }
    return (
        <>
        <div className="setting-section haft-whitebox cia-table cia-table-enable-number">
        <table className={`tablebox search-result-table inventory-table`}>
            <thead>
                <tr>
                   
                    <th>Caller ID</th>
                    <th>Date</th>
                    <th>Stir Shaken Attestation Audit</th>
                    <th>CNAM Audit</th>
                    <th>Scan Risk Levels</th>
                    <th>Scan FTC Report</th>
                    {
                        carriers.map((item, index) => (
                            <React.Fragment key={index}>
                            <th>{item.name} Caller ID Status (Android)</th>
                            {isAdvanceFieldChecked && <th>{item.name} App Status (Andriod)</th>}
                            {isAdvanceFieldChecked && <th>{item.name} OCR (Andriod)</th>}
                            <th>{item.name} Caller ID Status (iOS) </th>
                            {isAdvanceFieldChecked && <th>{item.name} App Status (iOS)</th>}
                            {isAdvanceFieldChecked && <th>{item.name} OCR (iOS)</th>}
                            </React.Fragment>
                        ))
                    }
                   
                    
                    
                </tr>
            </thead>
            <tbody>
                {dateData && dateData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        <td>{row.tn_number}</td>
                        <td>{row.call_date_time}</td>
                        <td>{row.attestation_audit}</td>
                        <td>{row.cnam}</td>
                        <td>{row.report_type != 3 ? (row.scan_risk_level != null && row.scan_risk_level !== '' && row.scan_risk_level != "-" ? `Risk level :  ${row.scan_risk_level}` : "-") : "-"}</td>
                        <td>{row.report_type != 3 ? scanFTCName(row.ftc_scan_report, row.ftc_subject,row.tn_number, row.call_date, row.ftc_created_date) : "-"}</td>
                        
                        {
                        carriers.map((item, index) => (
                            <React.Fragment key={`${rowIndex}-${index}`}>
                                <td>{row.report_type != 2 ? getStatusName(row[`android_status_carrier_${item.id}`], row[`screenshot_android_carrier_${item.id}`]) : "-"}</td>
                                {isAdvanceFieldChecked && <td>{row.report_type != 2 ? row[`device_status_android_carrier_${item.id}`] : "-"}</td>}
                                {isAdvanceFieldChecked && <td>{row.report_type != 2 ? row[`screenshot_text_android_carrier_${item.id}`] : "-"}</td>}
                                <td>{row.report_type != 2 ? getStatusName(row[`ios_status_carrier_${item.id}`], row[`screenshot_ios_carrier_${item.id}`]): "-"}</td>
                                {isAdvanceFieldChecked && <td>{row.report_type != 2 ? row[`device_status_ios_carrier_${item.id}`]:"-"}</td>}
                                {isAdvanceFieldChecked && <td>{row.report_type != 2 ? row[`screenshot_text_ios_carrier_${item.id}`]:"-"}</td>}
                            </React.Fragment>
                        ))
                    }
                       
                    </tr>
                ))}
            </tbody>
        </table>
       
        </div>
         {dateTotalItem > 120 && (<Pagination
            activePage={datePage}
            itemsCountPerPage={120}
            totalItemsCount={dateTotalItem}
            pageRangeDisplayed={5}
            onChange={handlePageChange.bind(this)}
          />)}
        </>
    )
}
export default CiaNumberDetails;