/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Pagination from "react-js-pagination";
import { useNavigate, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import formatDateTimeToPST from "../PSTDateConvert";
import Loader from "../Loader";

const OrderCron = () => {
    const [name, setName] = useState('');
    const [timeZone, seTimeZone] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [page, setPage] = useState(1);
    const [searchNumberValue, setSearchNumberValue] = useState('');
    const [status, setStatus] = useState("");
    const [searchDate, setSearchDate] = useState("");
    const [searchEndDate, setSearchEndDate] = useState("");
    const [displayDate, setDisplayDate] = useState("");
    const [displayEndDate, setDisplayEndDate] = useState("");
    const [popupOpen, setPopupOpen] = useState(false);
    const [type, setType] = useState("");
    const [message, setMessage] = useState(null); // To store the message
    const [messageType, setMessageType] = useState(""); // "success" or "error"
    
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();
        getOrder();
        //getUserCompany();
    }, []);

    // useEffect(() => {
    //     if(access.length > 0 && token){
    //         checkAccess(access, token);
    //     }
    // }, [access, token]);

 
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setName(decoded.name);
            seTimeZone(decoded.timezone);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }


    const handleSearchDate = (date) => { 
        console.log(date);  
        const formattedDate =
        date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
         date.getDate().toString().padStart(2, '0');
      
         
        setSearchDate(formattedDate);
       
        setDisplayDate(date);
    };


    const handleSearchSubmit = async () => {
        // setPage(1);

        // await axios.post(`${baseUrl}/save-order-cron`,updateObj,{headers: {
        //     Authorization: `Bearer ${token}`
        // }});
        // const response =  axios.post(`${baseUrl}/save-order-cron`, {date:searchDate},{
        //     headers: {
        //          Authorization: `Bearer ${token}`,
        //          "Content-Type": "multipart/form-data",
        //     }
        // });
        setPopupOpen(true);
        const response = await axios.post(
        `${baseUrl}/save-order-cron`,
        { date: searchDate }, // Request body
        {
            headers: {
            Authorization: `Bearer ${token}`, // Authorization header
            "Content-Type": "application/json", // Ensure the content type is JSON
            },
        }
        );

        // Handle successful response
        console.log("Response:", response.data);
        if(response.data.status == true){
            setPopupOpen(false);
            setMessage("Operation completed successfully!");
            setMessageType("success");
        }else{
            setMessage(`An error occurred. Due to ${response?.data?.error}.`);
            console.log(response?.data?.error);
            setMessageType("error");
        }
        setSearchDate('');
        setDisplayDate('');
        getOrder();
        //setPopupOpen(false);
    };

    // Function to clear the message
    const clearMessage = () => {
        setMessage(null);
        setMessageType("");
    };

    // Extract the date using RegExp
    const extractDate = (path) => {
        const match = path.match(/\d{4}-\d{2}-\d{2}/); // Looks for the format YYYY-MM-DD

        if (match) {
            const [year, month, day] = match[0].split("-"); // Split the matched date
            return `${day}/${month}/${year}`; // Return in d/m/Y format
        }
        return null; // Return null if no date is found

        //return match ? match[0] : null; // Returns the date if found, otherwise null
    };

    const getOrder = async () => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/order-csv-list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        console.log(response.data.data);
       
        //const result = response.data.data;
       
        setOrders(response.data.data);
        // setTotalItem(response.data.totalItem);
    }

    const handleDownload = async(item) => {

        setPopupOpen(true);
        const fullPath = item;
        console.log(fullPath, "path")
        const response = await axios.post(`${baseUrl}/download-uptivity-file`, {path : fullPath}, 
        {responseType: 'blob' // Set the response type to 'blob' to handle binary data
      });
      
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
    
        // Create a temporary link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', item.fileName); // Set the downloaded file name
        document.body.appendChild(link);
    
        // Trigger the download
        link.click();
    
        // Clean up
        link.remove();
        setPopupOpen(false);
      };

    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            seTimeZone(decoded.timezone);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    return (
        
        <div className="panelbox">
            {popupOpen && (
                     <Loader /> 
                ) }
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
            
                <div className="content-page">
                {/* Message Display */}
                {message && (
                    <div
                    style={{
                        padding: "10px",
                        marginBottom: "10px",
                        color: messageType === "success" ? "green" : "red",
                        border: `1px solid ${messageType === "success" ? "green" : "red"}`,
                        borderRadius: "5px",
                    }}
                    >
                    {message}
                    <button
                        onClick={clearMessage}
                        style={{
                        marginLeft: "10px",
                        background: "transparent",
                        border: "none",
                        color: messageType === "success" ? "green" : "red",
                        cursor: "pointer",
                        }}
                    >
                        ✖
                    </button>
                    </div>
                )}
                    <div className="row">
                        <div className="col-8">
                            <div className="left-title">
                                <h1>Order Verification</h1>
                            </div>
                        </div>
                        {/* <div className="col-4 top-right-buttons">                                        
                            <div className="export-btn addnew-button"><Link to="/number-management"><span>&larr;</span> Back</Link></div>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-md-12 order-filter transection-filter">
                            <div className="boxinput">
                            
                                <DatePicker
                                    selected={displayDate}
                                    onChange={handleSearchDate}
                                    dateFormat="MM/dd/yyyy" // Set the desired date format
                                    placeholderText="Date"
                                    className="from-date"
                                />
                                {/* <DatePicker
                                    selected={displayEndDate}
                                    onChange={handleSearchEndDate}
                                    dateFormat="MM/dd/yyyy" // Set the desired date format
                                    placeholderText="To Date"
                                    className="end-date"
                                />      */}
                             
                                <button  className="search-btn" onClick={handleSearchSubmit}>Submit</button>
                               {/* { (selectedCompany || status|| type || searchValue || searchDate || searchNumberValue || searchEndDate) && (<a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>)} */}
                            </div>
                        </div>
                        
                    </div>
                   
                    <div className="common-white-shadow-background setting-section">
                        <div className="row">
                        <div className=" setting-section haft-whitebox">
                            <div className="search-result-section">
                                <h3></h3>
                                {/* <div className="export-btn newrow"><a className="newrow" href="#">Export</a></div> */}
                            </div>
                                <table className="tablebox search-result-table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "10%" }}>ID</th>
                                            <th style={{ width: "15%" }}>Date</th>
                                            <th>Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {orders.map((item, index) => (
                                        <tr key={index}> 
                                            <td>{index+1}</td>
                                            <td>{extractDate(item.fileName)}</td>
                                            <td><a className="breadcrumb-link" href={item.url} rel="noopener noreferrer"><img src="/images/download.png" /><span style={{marginLeft: "20px"}}></span></a></td>
                                        </tr>))}
                                    </tbody>
                                </table>
                                
                                {/* {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)} */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default OrderCron