
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import formatDateTimeToPST from "../PSTDateConvert";
import { useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
 
const PublishManagement = () => {

    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);    
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedStatus,setSelectedStatus] = useState("");
    const [company, setCompany] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [batchList, setBatchList] = useState([]);
    const [access, setAccess] = useState([]);
    const [page, setPage] = useState(1);

    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();
        getUserCompany();
    }, []);

    useEffect(() => {
        if(role > 2){
            checkAccess();
        }
    }, [role]);

    useEffect(() => {
        getBatchList(page, selectedStatus, selectedValue);
    }, [page]);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const checkAccess = async () => {
        navigate('/dashboard');
    }

    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const allArray = response.data.filter(item => item.id !== 1);
        setCompany(allArray);
    }

    const getBatchList = async (page, status, cid) => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/get-enrms-batch-list?page=${page}&status=${status}&cid=${cid}&limit=${20}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        setBatchList(response.data.data);
        setTotalItem(response.data.totalItem);
    }

    const handlePageChange  = (pageNumber) => {
        setPage(pageNumber);
    }
    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);          
    };
    const handleSearchSubmit = () => {
        setPage(1);
        getBatchList(1, selectedStatus, selectedValue);
        // selectedStatus()
    };

    const clearFilter = () => {
        setPage(1);
        setSelectedValue("");
        setSelectedStatus("");
        getBatchList(1, "", "");
        
    };

    const handlePublishBatch = async (event, index, id) => {
        const { name, checked } = event.target;
        setBatchList((prevSetting) => {
            const updatedFormData = [...prevSetting];
            updatedFormData[index] = {
                ...updatedFormData[index], // Spread the existing fields of the object
                [name]: checked ? 2 : 1,  // Update the specific field based on 'checked' value
            };
            return updatedFormData;
        });
       
       const action = checked ? 2 : 1;
        try {
            await axios.get(`${baseUrl}/enrm-batch-publish/${id}?action=${action}`,{headers: {
                Authorization: `Bearer ${token}`
            }});
            getBatchList(page, selectedStatus, selectedValue);
            // window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }

    const handleStoprunningBatch = async (e,id) => {
        e.preventDefault();
        try {
            await axios.get(`${baseUrl}/enrm-batch-stop/${id}`,{headers: {
                Authorization: `Bearer ${token}`
            }});
            // window.location.reload();
            getBatchList(page, selectedStatus, selectedValue);
        } catch (error) {
            console.log(error);
        }
    }

    const handleRemoveBatch = async(e, id) => {
        e.preventDefault();
        try {
            await axios.get(`${baseUrl}/enrm-batch-remove/${id}`,{headers: {
                Authorization: `Bearer ${token}`
            }});
            // window.location.reload();
            getBatchList(page, selectedStatus, selectedValue);
        } catch (error) {
            console.log(error);
        }
    }
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    return (
        <div className="panelbox">
            <Navbar token={token} access={access}/>
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                    <Loader /> 
                ) }
                <div className="content-page admin-attendance-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Publish Management</h1>
                            </div>
                        </div>
                        <div className="col-6 inventory-btn">
                            <div className="addnew-button">
                                <Link to="/enrms-management"><span>&larr;</span> Back</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 order-filter">
                            <div className="boxinput">
                            
                                
                            <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                                    <option value="">Select Company</option>
                                    {company.map((comp) => ( 
                                            <option key={comp.id} value={comp.id}>
                                                {comp.cname}
                                            </option>  
                                        ))}
                                </select>
                                <select className="form-control" name='carrierStatus' value={selectedStatus} onChange={(e)=>{setSelectedStatus(e.target.value)}}>
                                        <option value=''>Select Status</option>
                                        <option value='1'>Running</option>
                                        <option value='2'>Complete</option>
                                        
                                    </select>
                                
                                <button  className="search-btn" onClick={handleSearchSubmit}>Search</button>
                               { ( selectedStatus || selectedValue ) && (<a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>)}
                            </div>
                        </div>
                    </div>
                    <div className="common-white-shadow-background setting-section">
                            <div className="setting-section haft-whitebox">
                                <table className="tablebox search-result-table inventory-table device-management-table">
                                    <thead>
                                    <tr>
                                        <th>Batch Id</th>
                                        <th>Date (UTC)</th>
                                        <th>Company</th>
                                        <th>Report Type</th>
                                        <th>Total Number</th>
                                        <th>Status</th>
                                        {role <= 2 && <th>Publish</th>}
                                        {role < 2 && <th>Action</th>}
                                        
                                        
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {batchList.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.batch_id}</td>
                                            <td>{formatDateTimeToPST(item.date, "UTC", 2)}</td>
                                            <td>{item.cname}</td>
                                            <td>{item.report_type == 1 ? "ENRMS" : (item.report_type == 2 ? "Basic NRMS" : (item.report_type == 3 ? "E-Only NRMS" : "-"))}</td>
                                            <td>{item.total_number}</td>
                                            <td>
                                                {item.status == 1 && <a href="#" className='newrow' onClick={(e) => handleStoprunningBatch(e,item.batch_id)} >Stop</a>}
                                                {item.status != 1 && "Complete"}
                                            </td>
                                            {role <= 2 &&<td>
                                            <div className="switchbtn">
                                                            <input type="checkbox" id={`action-${index}`}  className="checkbox"   checked={item.publish_type == 2} name="publish_type" onChange={(e) => handlePublishBatch(e,index,item.batch_id)} /> <label
                                                                htmlFor={`action-${index}`} className="toggle">
                                                                <p>
                                                                </p>
                                                            </label>
                                                        </div>
                                            </td>}
                                            {role < 2 && <td><a href='#'><img src="images/delete.png" onClick={event => handleRemoveBatch(event, item.batch_id)} alt="" /></a></td>}
                                            
                                            
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                
                            </div>
                            {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                        </div>
                </div>
        
            </div>
            </div>
    )
}
 
export default PublishManagement
