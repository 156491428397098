/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import { useNavigate, Link } from 'react-router-dom';
axios.defaults.timeout = 300000; 

const Step1 = ({ onNext,cid, role,token, formData, setFormData }) => {
   
    const [loading, setLoading] = useState(true); 
    
   
    const [companyList, setcompanyList] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
   
    const [file, setFile] = useState(null);
    const [errorList, setErrorList] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [showCustomerValidationError, setShowCustomerValidationError] = useState({
        cid: false,
        file: false
    });
   
    const errorListRef = useRef(null);
    const navigate = useNavigate();
   
    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
    };
    useEffect(() => {
        if(token){
         getCustomerNameList();
        }
         
     }, [token]);

   
    useEffect(() => {
        if(cid){
            // const compId =  role <= 2 ? selectedValue : cid ;
            console.log(cid);
            // setFormData({ ...formData, selectedCompany: compId });
            setFormData({ ...formData, selectedCompany: cid });
        }
        
    }, [selectedValue, cid]);

    useEffect(() => {
        // Scroll to the errorListRef if Object.entries(errorList).length > 0
        if (Object.entries(errorList).length > 0) {
            console.log("scroll");
          errorListRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [errorList]);
     

    const getCustomerNameList = async () => {
        try {
          
            const response = await axios.get(`${baseUrl}/active-user-company`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            const allArray = response.data.filter(item => item.id !== 1);
            setcompanyList(allArray);
        } catch (error) {
            setLoading(false);
           console.log(error);
            
        }
    };



    // const handleCompanyChange = (e) => {
    //     setSelectedValue(e.target.value);
    //     console.log(formData);
    //     const compId =  role <= 2 ? cid : e.target.value;
    //     setFormData({ ...formData, selectedCompany: e.target.value });
    // };


    const handleNextClick = async(e) => {
        try{
            e.preventDefault();
            let hasCustomerValidationError = false;
            const errors = {};
            if (selectedValue === '') errors.cid = true;
            if (!file) errors.file = true;
            
            if (Object.keys(errors).length > 0) {
                setShowCustomerValidationError({ ...showCustomerValidationError, ...errors });
                hasCustomerValidationError = true;
            }
            const newData = new FormData();
            newData.append("file", file);                
            newData.append('company_id', formData.selectedCompany);
            setPopupOpen(true);
            const response = await axios.post(`${baseUrl}/validate-dailed-number-status`, newData,{
                headers: {
                     Authorization: `Bearer ${token}`,
                     "Content-Type": "multipart/form-data",
                }
            });
            const allArray = response.data;
            setPopupOpen(false);
            if(allArray.success){
                navigate("/order-new-number-success", { state: {
                    checkTNSDetail : true
                } }); 
                // navigate('/dialed-number/list')
            }else{
                let modifedError = [];
                if (Array.isArray(allArray.errors) && allArray.errors.length > 0) {
                    allArray.errors.map((item) => {
                        const row = item.row;
                        const errors = item.errors.map(err => err.error).join(", ");
                        const obj = {
                            "row": "Row " + row,
                            "error": errors
                        };
                        modifedError.push(obj);
                    });
                } else {
                    modifedError.push({ "row": allArray.errors });
                }
                setErrorList(modifedError);
            }
        }catch(e){
            console.log(e);
            navigate("/order-new-number-success", { state: {
                checkTNSDetail : true
            } }); 
            // navigate('/dialed-number/list')
        }
    };
    useEffect(() => {
        console.log(showCustomerValidationError);
    }, [showCustomerValidationError]);

    return(
        <>
            <div className="row">
                <div className="col-6">
                    <div className="left-title">
                        <h1>Check Telephone Number Status</h1>
                    </div>
                </div>
                <div className="col-6">
                </div>
            </div>
            {popupOpen && (
                     <Loader /> 
                ) }
            
            {/* {role <= 2 && <div className="common-white-shadow-background setting-section">
            <div className="row">
                <h3 className='comapny-label'>Select Company</h3>
                <div className="inputbox">
                    <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                        <option value="">Select Company</option>
                        {companyList.map((comp) => (                                                                    
                                <option key={comp.id} value={comp.id}>
                                    {comp.cname}
                                </option>  
                            ))}
                     
                    </select>
                </div>
            </div>
            {showCustomerValidationError.cid && (
        <p className="error">Please select a value from the dropdown.</p>
      )}
      </div>} */}
            
            
            
            <div className="common-white-shadow-background setting-section">
                <div className="row">
                    
                    <h3>Upload File (Using Template)</h3>
                    <div className="download-templte">
                        <a className="newrow" target="_blank" href={`${process.env.PUBLIC_URL}/template/DialedNumberStatusCheck_Template.csv`}>Download Template</a>
                        <p>Submissions must be in the format of this template</p>
                    </div>
                    <div className="file-upload-field">
                        <div className="upload-btn-wrapper">
                              <input type="file" name="myfile" onChange={handleFileChange} placeholder="Select your local file based on our template" />
                            </div>
                            {showCustomerValidationError.file && (
        <p className="error">Please upload a valid file.</p>
      )}
                    </div>
                    
                </div>
            </div>
            <div className="row footer-row">
            <Link className="Cancel" to={"/dialed-number/list"}>Cancel</Link>
                <a href="#" className="next" onClick={handleNextClick}>Submit</a>
            </div>
            
            <div style={{'color':"red","paddingBottom":"30px"}} ref={errorListRef}>{ Object.entries(errorList).length > 0 && <p>Please resolve below errors</p>}<table  className="tablebox search-result-table order-result-table">
                                        
                                        <tbody>
                                        {errorList.map((item, index) => (
                    <tr key={index}>
                        <td>{item.row}</td>
                        <td>{item.error}</td>
                    </tr>
                ))}
                                        </tbody>
                                    </table></div>
        </>
    );
}

 
const CheckDialedNumber = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [cid, setCid] = useState('');
    const [formData, setFormData] = useState([]);
    const [step, setStep] = useState(1);
   
    

    
    
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);

    useEffect(() => {
        if(cid){
            getCustomerTNSAccess();
        }
    }, [cid]);

    useEffect(() => {
        if(role){
            checkTnsAccessPermission();
        }
    }, [role]);
    
    // useEffect(() => {
    //     if(access.length > 0 && token){
    //         checkAccess(access, token);
    //     }
    // }, [access, token]);
    
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const checkTnsAccessPermission= async () => {
        const response = await axiosJWT.get(`${baseUrl}/check-tns-permission`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data.isTnsPermission);
        const isTnsPermission = (response.data.isTnsPermission==true) ? 1 : 0;
        if (isTnsPermission === 0 && role > 2) {
            // Navigate to the specified route if the API response is true
            navigate("/dashboard");
        } 
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    // const hasPermission = (permission) => {
    //     return access.some((item) => item.access === permission);
    // };

    // const checkAccess = async (access) => {
    //     if(!hasPermission('ordernewnumber') || role > 2){
    //         navigate('/dashboard');
    //     }
    // }
    const getCustomerTNSAccess = async () => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            console.log(response.data);
           
            // setTNSAccess(response.data.api_enable_switch === 1 ? true : false);
            const checAccess = response.data.api_enable_switch === 1 ? true : false;
            if(!checAccess && role > 2){
                navigate('/dashboard');
            }
        } catch (error) {
            // setLoading(false);
            
           console.log(error);
            
        }
    };
    
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleNext = () => setStep(step + 1);
    const handleBack = () => setStep(step - 1);
 
    const renderStep = () => {
        switch (step) {
          case 1:
            return <Step1 onNext={handleNext} cid={cid} role={role} token={token} formData={formData} setFormData={setFormData} />;
         
          default:
            return null;
        }
      };
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
                <div className="content-page">
                    {renderStep()}
                </div>
            </div>
        </div>
    )
}
 
export default CheckDialedNumber