
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import MFA from "./components/MFA";

import UserList from "./components/UserManagement/user/List";
import CustomerCompanyList from "./components/UserManagement/customer_company/List";
import UserCompanyAdd from "./components/UserManagement/customer_company/UserCompanyAdd";
import EditCustomer from "./components/UserManagement/customer_company/EditCustomer";


import AddUser from "./components/UserManagement/user/AddUser";
import EditUser from "./components/UserManagement/user/EditUser";

import SetPassword from "./components/SetPassword";
import ForgotPassword from "./components/ForgotPassword";
import ResellerList from "./components/UserManagement/reseller/List";
import ResellerAdd from "./components/UserManagement/reseller/ResellerAdd";
import EditReseller from "./components/UserManagement/reseller/EditReseller";

import Profile from './components/Profile';
import NumberManagement from './components/NumberManagement/Index';
import CreateOrderNumber from './components/NumberManagement/OrderNewNumber/CreateOrder';
import CreateOrderNumberSuccess from './components/NumberManagement/OrderNewNumber/CreateOrderSuccess';
import ViewOrder from './components/NumberManagement/ViewOrder';
import ViewOrderDetail from './components/NumberManagement/ViewOrderDetail';
import ModifyNumber from './components/NumberManagement/ModifyNumber';
import CancelNumber from './components/NumberManagement/CancelNumber';
import InventoryList from './components/NumberManagement/InventoryList';
import ViewTransactions from "./components/NumberManagement/ViewTransactions";
import ImportInvenotry from "./components/NumberManagement/ImportInventory";
import ViewTrunkDetail from './components/NumberManagement/ViewTrunkDetail';
import EditTrunkDetail from './components/NumberManagement/EditTrunkDetail';
import EditMultipleTrunkDetail from './components/NumberManagement/EditMultipleTrunkDetail';
import TrunkCronSync from './components/NumberManagement/TrunkCronSync';
import FeaturesNumber from './components/NumberManagement/FeaturesNumber';

import ImportNonSupportCarrierNumber from "./components/NumberManagement/NonCarrierNumber/ImportNonSupportCarrierNumber";

import DigitMappingList from "./components/NumberManagement/DigitMapping/List";
import AddDigitMapping from "./components/NumberManagement/DigitMapping/AddDigitMapping";
import EditDigitMapping from "./components/NumberManagement/DigitMapping/EditDigitMapping";
import AddMultipleDigitMapping from "./components/NumberManagement/DigitMapping/AddMultipleDigitMapping";

import TrafficAnalyticsReporting from './components/NumberManagement/Report/TrafficAnalyticsReporting';
import RealTimeReportAnalytics from './components/NumberManagement/Report/RealTimeReportAnalytics';
import ReportAnalytics from './components/NumberManagement/Report/ReportAnalytics';
import CDRReportAnalytics from "./components/NumberManagement/Report/CDRAnalytics";

import UptivityAnalytics from "./components/Uptivity/Analytics";
import CitibankUptivityAnalytics from "./components/Uptivity/CitibankUptivityAnalytics";
import InactivityTimeout from "./components/InactivityTimeout";
import ImportInventoryHistory from "./components/NumberManagement/ImportInventoryHistory";
import ImportDigitMapping from "./components/NumberManagement/DigitMapping/ImportDigitMapping";

import DialedNumberList from "./components/DialedNumber/List";
import CapiLogs from "./components/Capi/Logs";
import CheckDialedNumberStatus from "./components/DialedNumber/CheckDialedNumber";
import ReviewRecordLog from "./components/DialedNumber/ReviewRecordLog";
import TelNumberApiLog from "./components/DialedNumber/TelNumberApiLog";
import TelNumberMonthsDetailLog from "./components/DialedNumber/TelNumberMonthsDetailLog"
import CasDashboard from "./components/CiaDashboard/CasDashboard";
import CiaBatchManagement from "./components/CiaDashboard/CiaBatchManagement";
import NumberCallHistory from "./components/CiaDashboard/NumberCallHistory";
import CiaNumberDetail from "./components/CiaDashboard/CiaNumberDetail";
import CiaNumberDetails from "./components/CiaDashboard/CiaNumberDetails";
import CiaNumberSetting from "./components/CiaDashboard/CiaNumberSetting";
import CiaNumberSettingHistory from "./components/CiaDashboard/CiaNumberSettingHistory";

import Settings from "./components/Settings/Index";
import CarrierSettings from "./components/Settings/CarreirSettings";
import ApplicationSettings from "./components/Settings/ApplicationSettings";

import CasImageTextRactList from "./components/CiaDashboard/CasImageTextRactList";

import ENRMSManagement from "./components/ENRMSManagement/Index";
import CarrierManagement from "./components/ENRMSManagement/CarrierManagement";
import GroupManagement from "./components/ENRMSManagement/GroupManagement";
import ResumeManagement from "./components/ENRMSManagement/ResumeManagement";
import DeviceManagement from "./components/ENRMSManagement/DeviceManagement";
import ENRMSSettings from "./components/ENRMSManagement/ENRMSSettings";
import DeviceApiLog from "./components/ENRMSManagement/DeviceApiLog";
import ImageStoreLog from "./components/ENRMSManagement/ImageStoreLog";
import PublishManagement from "./components/ENRMSManagement/PublishManagement";
import ScheduleSummaryList from "./components/ENRMSManagement/ScheduleSummaryList";


import OrderCron from './components/NumberManagement/OrderCron';


const URLS = () => {
  return (
    <BrowserRouter>
    <InactivityTimeout>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/mfa" element={<MFA />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path="/user-management" element={<UserManagement />} /> */}
        <Route path="/user-company/list" element={<CustomerCompanyList />} />
        {/* <Route path="/user-company/:id" element={<ItemDetailComponent />} /> */}
        <Route path="/user-company/edit/:id" element={<EditCustomer />} />
        {/* <Route path="/user-company/review" element={<ReviewCustomer />} /> */}
        <Route path="/user-company-add" element={<UserCompanyAdd />} />
        <Route path="/user-reseller/list" element={<ResellerList />} />
        <Route path="/user-reseller/edit/:id" element={<EditReseller />} />
        <Route path="/user-reseller-add" element={<ResellerAdd />} />
        {/* <Route path="/user-reseller/review" element={<ReviewReseller />} /> */}
        <Route path="/user" element={<UserList />} />
        <Route path="/user-add" element={<AddUser />} />
        <Route path="/user/edit/:id" element={<EditUser />} />
        <Route path="/set-password/:token" element={<SetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/profile" element={<Profile />} />
        

        <Route path="/number-management" element={<NumberManagement />} />
        <Route path="/order-new-number" element={<CreateOrderNumber />} />
        <Route path="/features-number" element={<FeaturesNumber />} />
        <Route path="/order-new-number-success" element={<CreateOrderNumberSuccess />} />
        <Route path="/view-order" element={<ViewOrder />} />
        <Route path="/view-order-detail/:id" element={<ViewOrderDetail />} />
        <Route path="/modify-number" element={<ModifyNumber />} />
        <Route path="/cancel-number" element={<CancelNumber />} />
        <Route path="/inventory" element={<InventoryList />} />
        <Route path="/view-transactions" element={<ViewTransactions />} />
        <Route path="/import-inventory" element={<ImportInvenotry />} />
        <Route path="/import-inventory-history" element={<ImportInventoryHistory/>}/>
        <Route path="/view-trunk-detail/:id" element={<ViewTrunkDetail />} />
        <Route path="/edit-trunk-detail/:id" element={<EditTrunkDetail />} />
        <Route path="/edit-multiple-trunk-detail" element={<EditMultipleTrunkDetail />} />
        <Route path="/trunk-cron-list" element={<TrunkCronSync />} />
        <Route path="/digit-mapping" element={<DigitMappingList />} />
        <Route path="/digit-mapping/add" element={<AddDigitMapping />} />
        <Route path="/digit-mapping/edit/:id" element={<EditDigitMapping />} />
        <Route path="/digit-mapping/import" element={<ImportDigitMapping />} />
        <Route path="/digit-mapping/add-multiple" element={<AddMultipleDigitMapping />} />
        <Route path="/traffic-and-analytics-reporting" element={<ReportAnalytics />} />
        <Route path="/resource-report" element={<TrafficAnalyticsReporting />} />
        <Route path="/real-time-report" element={<RealTimeReportAnalytics />} />
        <Route path="/cdr-report" element={<CDRReportAnalytics />} />
        <Route path="/uptivity" element={<UptivityAnalytics />} />
        <Route path="/citibank-uptivity" element={<CitibankUptivityAnalytics />} />
        <Route path="/cas-management" element={<CasDashboard/>}/>
        {/* <Route path="/cia-batch-management" element={<CiaBatchManagement/>}/> */}
        <Route path="/number-call-history" element={<NumberCallHistory/>}/>
        <Route path="/cia-number-detail" element={<CiaNumberDetails/>}/>
        <Route path="/cia-number-details" element={<CiaNumberDetails/>}/>
        <Route path="/cia-number-setting" element={<CiaNumberSetting/>}/>
        <Route path="/cia-number-setting-history" element={<CiaNumberSettingHistory/>}/>
        <Route path="/dialed-number/list" element={<DialedNumberList />} />
        <Route path="/capi/logs" element={<CapiLogs />} />
        <Route path="/check-dialed-number-status" element={<CheckDialedNumberStatus />} />
        <Route path="/review-record-log" element={<ReviewRecordLog />} />
        <Route path="/tel-number-api-log" element={<TelNumberApiLog />} />
        <Route path="/tel-number-months-detail-log" element={<TelNumberMonthsDetailLog />} />

        <Route path="/import-non-support-carrier-number" element={<ImportNonSupportCarrierNumber />} />

        <Route path="/settings" element={<Settings />} />
        <Route path="/application-settings" element={<ApplicationSettings />} />
        <Route path="/carrier-settings" element={<CarrierSettings />} />
        {/* <Route path="*" element={<NotFound />} /> */}
        {/* <Route path="/cas-platform-setup" element={<CasPlatformSetup />} /> */}
        <Route path="/cas-image-texttact" element={<CasImageTextRactList />} />

        <Route path="/enrms-management" element={<ENRMSManagement />} />
        <Route path="/enrms-carrier-management" element={<CarrierManagement />} />
        <Route path="/enrms-settings" element={<ENRMSSettings />} />
        <Route path="/enrms-group-management" element={<GroupManagement />} />
        <Route path="/enrms-resume-management" element={<ResumeManagement />} />
        <Route path="/enrms-device-management" element={<DeviceManagement />} />
        <Route path="/enrms-device-api-log" element={<DeviceApiLog/>}/>
        <Route path="/enrms-image-api-log" element={<ImageStoreLog/>}/>
        <Route path="/enrms-publish-management" element={<PublishManagement />} />
        <Route path="/enrms-schedule-summary" element={<ScheduleSummaryList />} />
        
        {/* Order review cron url */}
        <Route path="/order-verification-list" element={<OrderCron />} />
        </Routes>
        </InactivityTimeout>
    </BrowserRouter>
  );
}

// function DashboardWithNavbar() {
//   return (
//     <>
//       <Dashboard />
//     </>
//   );
// }
 
export default URLS;